import { TrashIcon } from "@heroicons/react/24/solid";
import moment from "moment";
import React from "react";
import { Bateau } from "src/api/bateaux/bateauTypes";
import SwitchButton from "../buttons/SwitchButton";
import * as bateauService from "src/api/bateaux/bateauService";

function BateauResume({
	bateau
}: //handleToggle
{
	bateau: Bateau;
	//handleToggle?: (value: boolean, inputName?: string) => void;
}) {
	const grille = ["", "Courte durée", "Longue durée"];
	const gamme = ["", "monocoque", "", "catamaran"];
	const handleToggle = (value: boolean, inputName?: string) => {
		if (inputName) {
			bateauService.updateBateau(bateau.id, { ...bateau, [inputName]: value });
		}
	};

	return (
		<div className="relative grid grid-cols-2 w-full text-xs text-left px-2 items-center overflow-hidden">
			<div className="flex flex-col items-center mb-auto">
				<div className="capitalize whitespace-nowrap">taille : </div>
				<div className="whitespace-nowrap font-bold">{bateau.taille}</div>
			</div>
			<div className="flex flex-col items-center mb-auto ">
				<div className="capitalize whitespace-nowrap">places : </div>
				<div className="text-center font-bold">{bateau.place_fr}</div>
			</div>

			<hr className="col-span-2 my-1" />

			<div className="flex flex-col items-center">
				<div className="capitalize whitespace-nowrap">type : </div>
				<div className="whitespace-nowrap">{bateau.gamme?.nom_fr}</div>
			</div>
			<div className="flex flex-col items-center">
				<div className="capitalize whitespace-nowrap">grille : </div>
				<div className="whitespace-nowrap">
					{bateau.grilles?.map((grille, g_index) => {
						return <React.Fragment key={g_index}>{grille.nom}</React.Fragment>;
					})}
				</div>
			</div>
			<hr className="col-span-2 my-1" />
			<div className="flex flex-col items-center">
				<div className="capitalize whitespace-nowrap">Annonce : </div>
				<div className="whitespace-nowrap">
					<SwitchButton
						initialValue={bateau.is_annonce}
						name={"is_annonce"}
						onToggle={handleToggle}
					/>
					{/* {bateau.is_annonce ? "oui" : "non"} */}
				</div>
			</div>
			<div className="flex flex-col items-center">
				<div className="capitalize whitespace-nowrap"> En location : </div>
				<div className="whitespace-nowrap">
					<SwitchButton
						initialValue={bateau.is_location}
						name={"is_location"}
						onToggle={handleToggle}
					/>
				</div>
			</div>
			<hr className="col-span-2 my-1" />
			<div className="flex flex-col col-span-2 items-center">
				<div className="capitalize whitespace-nowrap">Caution : </div>
				<div className="whitespace-nowrap font-bold">{bateau.caution} €</div>
			</div>
			<div className="flex flex-col col-span-2 items-center">
				{bateau.annonce_prix !== undefined && bateau.annonce_prix !== 0 && (
					<>
						<div className="capitalize whitespace-nowrap">Prix : </div>
						<div className="whitespace-nowrap font-bold">
							{bateau.annonce_prix} €
						</div>
					</>
				)}
			</div>
		</div>
	);
}

export default BateauResume;
