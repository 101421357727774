import React, { useEffect, useState } from "react";
import moment from "moment";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";

import { useAppSettingStore } from "src/services/useAppSettingStore";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as enums from "src/lib/enums";
import { Bateau } from "src/api/bateaux/bateauTypes";
import { fetchBateaux } from "src/api/bateaux/bateauService";
import BateauMenu from "./BateauMenu";
import BateauList from "./BateauList";
import BateauForm from "./BateauForm";
function BateauHome() {
	const navigate = useNavigate();
	let { id } = useParams();

	const [currentPage] = useAppSettingStore((state) => [state.currentPage]);

	let location = useLocation();

	const [bateaux, setBateaus] = useState<Bateau[]>([]);

	// const [selectedBateau, setSelectedBateau] = useState<Bateau | undefined>(
	// 	bateaux.find((art) => id && art.id?.toString() === id)
	// );

	const selectedBateau = bateaux.find((art) => id && art.id?.toString() === id);
	const [loading, setLoading] = useState(true);
	const handleSelectBateau = (bateau?: Bateau) => {
		if (bateau) {
			navigate(`${enums.PAGES_BOAT_EDIT}/${bateau.id}`);
		} else {
			navigate(enums.PAGES_BOAT_ADD);
		}
		//setSelectedBateau(bateau);
	};
	useEffect(() => {
		const loadBateaux = async () => {
			try {
				const data = (await fetchBateaux()) as Bateau[];

				setBateaus(
					data.sort((a, b) => {
						return (
							parseInt(moment(b.created_at).format("YYYYMMDDHHmm")) -
							parseInt(moment(a.created_at).format("YYYYMMDDHHmm"))
						);
					})
				);
			} catch (error: any) {
				debugger;
				if (error.response.data === "Failed to authenticate token.") {
					navigate("/login");
				}

				console.error("Failed to fetch bateaux", error);
			} finally {
				setLoading(false);
			}
		};

		loadBateaux();
	}, []);

	useEffect(() => {
		//setSelectedBateau(undefined);
	}, [location.pathname]);
	if (loading) return <p>Loading...</p>;

	return (
		<div className="flex flex-col w-full  h-full overflow-hidden">
			<BateauMenu handleSelectBateau={handleSelectBateau} />
			{location.pathname.includes(enums.PAGES_BOAT_LIST) ? (
				<>
					<BateauList handleSelectBateau={handleSelectBateau} />
				</>
			) : location.pathname.includes(enums.PAGES_BOAT_EDIT) ||
			  location.pathname.includes(enums.PAGES_BOAT_ADD) ? (
				<BateauForm selectedBateau={selectedBateau} />
			) : (
				""
			)}
		</div>
	);
}

export default BateauHome;
