import React, { useEffect, useState } from "react";
import { fetchArticles } from "../../api/articles/articleService";
import { Article } from "../../api/articles/articleTypes";
import moment from "moment";
import ArticleMenu from "./ArticleMenu";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";

import { useAppSettingStore } from "src/services/useAppSettingStore";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as enums from "src/lib/enums";
import ArticleList from "./ArticleList";
import ArticleForm from "./ArticleForm";

function ArticleHome() {
	const navigate = useNavigate();
	let { id } = useParams();

	const [currentPage] = useAppSettingStore((state) => [state.currentPage]);

	let location = useLocation();

	const [articles, setArticles] = useState<Article[]>([]);

	// const [selectedArticle, setSelectedArticle] = useState<Article | undefined>(
	// 	articles.find((art) => id && art.id?.toString() === id)
	// );

	const selectedArticle = articles.find(
		(art) => id && art.id?.toString() === id
	);
	const [loading, setLoading] = useState(true);
	const handleSelectArticle = (article?: Article) => {
		if (article) {
			navigate(`${enums.PAGES_ARTICLE_EDIT}/${article.id}`);
		} else {
			navigate(enums.PAGES_ARTICLE_ADD);
		}
		//setSelectedArticle(article);
	};
	useEffect(() => {
		const loadArticles = async () => {
			try {
				const data = (await fetchArticles()) as Article[];

				setArticles(
					data.sort((a, b) => {
						return (
							parseInt(moment(b.created_at).format("YYYYMMDDHHmm")) -
							parseInt(moment(a.created_at).format("YYYYMMDDHHmm"))
						);
					})
				);
			} catch (error: any) {
				debugger;
				if (error.response.data === "Failed to authenticate token.") {
					navigate("/login");
				}

				console.error("Failed to fetch articles", error);
			} finally {
				setLoading(false);
			}
		};

		loadArticles();
	}, []);

	useEffect(() => {
		//setSelectedArticle(undefined);
	}, [location.pathname]);

	if (loading) return <p>Loading...</p>;

	return (
		<div className="flex flex-col w-full  h-full overflow-hidden">
			<ArticleMenu handleSelectArticle={handleSelectArticle} />
			{location.pathname.includes(enums.PAGES_ARTICLE_LIST) ? (
				<ArticleList handleSelectArticle={handleSelectArticle} />
			) : location.pathname.includes(enums.PAGES_ARTICLE_EDIT) ||
			  location.pathname.includes(enums.PAGES_ARTICLE_ADD) ? (
				<ArticleForm selectedArticle={selectedArticle} />
			) : (
				""
			)}
		</div>
	);
}

export default ArticleHome;
