import React, { useState, useEffect } from "react";
import { checkImageExists } from "src/lib/functions";

interface ImageProps {
	src: string;
	alt: string;
	className?: string;
}

const SafeImage: React.FC<ImageProps> = ({ src, alt, className }) => {
	const [imageExists, setImageExists] = useState<boolean>(false);

	useEffect(() => {
		const checkImage = async () => {
			const exists = await checkImageExists(src);
			setImageExists(exists);
		};

		checkImage();
	}, [src]);

	if (!imageExists) {
		return (
			<div className="flex bg-white p-3 w-full h-40 text-center text-xs items-center justify-center">
				Image introuvable
			</div>
		); //<div>Image not found</div>; // or you could return null or a placeholder image
	}

	return (
		<img
			src={src}
			alt={alt}
			className={className}
		/>
	);
};

export default SafeImage;
