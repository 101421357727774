import React, { useEffect, useState } from "react";
import { fetchEscales } from "../../api/escales/escaleService";
import { Escale } from "../../api/escales/escaleTypes";
import moment from "moment";
import EscaleMenu from "./EscaleMenu";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";

import { useAppSettingStore } from "src/services/useAppSettingStore";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as enums from "src/lib/enums";
import EscaleForm from "./EscaleForm";
import EscaleList from "./EscaleList";

function EscaleHome() {
	const navigate = useNavigate();
	let { id } = useParams();

	const [currentPage] = useAppSettingStore((state) => [state.currentPage]);

	let location = useLocation();

	const [escales, setEscales] = useState<Escale[]>([]);

	// const [selectedEscale, setSelectedEscale] = useState<Escale | undefined>(
	// 	escales.find((art) => id && art.id?.toString() === id)
	// );

	const selectedEscale = escales.find((art) => id && art.id?.toString() === id);
	const [loading, setLoading] = useState(true);
	const handleSelectEscale = (escale?: Escale) => {
		if (escale) {
			navigate(`${enums.PAGES_ESCALE_EDIT}/${escale.id}`);
		} else {
			navigate(enums.PAGES_ESCALE_ADD);
		}
		//setSelectedEscale(escale);
	};
	useEffect(() => {
		const loadEscales = async () => {
			try {
				const data = (await fetchEscales()) as Escale[];

				setEscales(data);
			} catch (error: any) {
				debugger;
				if (error.response.data === "Failed to authenticate token.") {
					navigate("/login");
				}

				console.error("Failed to fetch escales", error);
			} finally {
				setLoading(false);
			}
		};

		loadEscales();
	}, []);

	useEffect(() => {
		//setSelectedEscale(undefined);
	}, [location.pathname]);

	if (loading) return <p>Loading...</p>;

	return (
		<div className="flex flex-col w-full  h-full overflow-hidden">
			<EscaleMenu handleSelectEscale={handleSelectEscale} />
			{location.pathname.includes(enums.PAGES_ESCALE_LIST) ? (
				<EscaleList handleSelectEscale={handleSelectEscale} />
			) : location.pathname.includes(enums.PAGES_ESCALE_EDIT) ||
			  location.pathname.includes(enums.PAGES_ESCALE_ADD) ? (
				<EscaleForm selectedEscale={selectedEscale} />
			) : (
				""
			)}
		</div>
	);
}

export default EscaleHome;
