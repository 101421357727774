import { Media } from "src/api/medias/mediaTypes";

export class Article {
	id?: number;
	created_at?: string;
	updated_at?: string;
	slug = "";
	titre_fr = "";
	titre_en = "";
	texte_fr = "";
	texte_en = "";
	extrait_fr = "";
	extrait_en = "";
	texte_md_fr = "";
	texte_md_en = "";
	seo_title_fr = "";
	seo_description_fr = "";
	seo_title_en = "";
	seo_description_en = "";
	type = 0;
	etat = 0;
	categorie_id = 0;
	utilisateur_id = 0;
	media_id?: number;
	media_data?: Media;
	media?: Media[];
}
