import React, { useEffect, useState } from "react";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { getNbArticles } from "src/api/articles/articleService";
import { getNbBateaux } from "src/api/bateaux/bateauService";
import { getNbCartes } from "src/api/cartes/carteService";
import { getNbContacts } from "src/api/contacts/contactService";
import { getNbEscales } from "src/api/escales/escaleService";
import { getNbMedias } from "src/api/medias/mediaService";

const Dashboard: React.FC = () => {
	const { user, logout } = useAuth();
	const [nbArticles, setNbArticles] = useState(0);
	const [nbCartes, setNbCartes] = useState(0);
	const [nbMedias, setNbMedias] = useState(0);
	const [nbBateaux, setNbBateaux] = useState(0);
	const [nbMessages, setNbMessages] = useState(0);
	const navigate = useNavigate();

	const handleLogout = () => {
		logout();
		navigate("/login");
	};

	const dashCategs: any[] = [
		{ title: "Article", nb: nbArticles, bgColor: "bg-purple-300" },
		{ title: "Cartes", nb: nbCartes, bgColor: "bg-green-300" },
		{ title: "Médias", nb: nbMedias, bgColor: "bg-yellow-300" },
		{ title: "Bateaux", nb: nbBateaux, bgColor: "bg-blue-300" },
		{ title: "Messages", nb: nbMessages, bgColor: "bg-rose-300" }
	];
	useEffect(() => {
		async function nbElementsToShow() {
			const nbArt = await getNbArticles();
			const nbBat = await getNbBateaux();
			const nbEsc = await getNbEscales();
			const nbMess = await getNbContacts();
			const nbMed = await getNbMedias();

			setNbArticles(nbArt);
			setNbCartes(nbEsc);
			setNbMedias(nbMed);
			setNbBateaux(nbBat);
			setNbMessages(nbMess);
		}

		if (!user) {
			navigate("/login");
		} else {
			nbElementsToShow();
		}
	}, [user]);

	return (
		<div className="grid grid-rows-[auto_auto_1fr] h-full w-full  p-4 overflow-hidden">
			<h1 className="flex text-2xl mb-4 gap-2 whitespace-nowrap">
				Bonjour <span className="capitalize">{user?.nom}</span>
				<span className="capitalize">{user?.prenom},</span>
			</h1>

			<div className="grid grid-cols-3 gap-4 w-full h-full mx-auto max-w-3xl overflow-y-auto">
				{dashCategs.map((categ, idx_categ) => (
					<div
						className={`flex flex-col rounded-md w-full h-full shadow p-4 ${categ.bgColor} bg-opacity-40 overflow-hidden min-h-[200px]`}
						key={idx_categ}
					>
						<div className="text-2xl font-bold rounded-md bg-white/30 p-2">
							{categ.title}
						</div>
						<div className=""></div>
						<div className=""></div>
						<div className="justify-center rounded-md bg-white mt-auto ml-auto p-2 w-12 h-12 items-center text-center font-bold text-lg">
							{categ.nb}
						</div>
					</div>
				))}
			</div>
			{/* Add navigation links to different administration pages */}
		</div>
	);
};

export default Dashboard;
