import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AuthProvider, useAuth } from "./context/AuthContext";

import Dashboard from "./pages/Dashboard";
import Articles from "./pages/Articles";
import Login from "./pages/Login";

import "./App.css";
import MediaGallery from "src/components/media/MediaGallery";
import ConfigurationPanel from "src/components/ConfigurationPanel";
import NavBar from "src/components/NavBar";
import ArticleHome from "src/components/articles/ArticleHome";
import BateauHome from "./components/bateaux/BateauHome";
import ContactList from "./components/contacts/ContactList";
import UserHome from "./components/users/UserHome";
import * as enums from "./lib/enums";
import EscaleHome from "./components/escales/EscaleHome";
import GrilleTarifHome from "./components/tarifs/GrilleTarifHome";

const App: React.FC = () => {
	return (
		<AuthProvider>
			<Router>
				<div className="grid grid-rows-[auto_1fr] h-full w-full overflow-hidden">
					<Routes>
						<Route
							path="/login"
							element={<Login />}
						/>
						<Route
							path="/"
							element={
								<>
									<NavBar />
									<Dashboard />
								</>
							}
						/>
						<Route
							path={`${enums.PAGES_ARTICLE_LIST}`}
							element={
								<>
									<NavBar />
									<ArticleHome />
								</>
							}
						/>
						<Route
							path={`${enums.PAGES_ARTICLE_EDIT}/:id`}
							element={
								<>
									<NavBar />
									<ArticleHome />
								</>
							}
						/>
						<Route
							path={`${enums.PAGES_ARTICLE_ADD}`}
							element={
								<>
									<NavBar />
									<ArticleHome />
								</>
							}
						/>
						<Route
							path={`${enums.PAGES_ESCALE_LIST}`}
							element={
								<>
									<NavBar />
									<EscaleHome />
								</>
							}
						/>
						<Route
							path={`${enums.PAGES_ESCALE_EDIT}/:id`}
							element={
								<>
									<NavBar />
									<EscaleHome />
								</>
							}
						/>
						<Route
							path={`${enums.PAGES_ESCALE_ADD}`}
							element={
								<>
									<NavBar />
									<EscaleHome />
								</>
							}
						/>
						<Route
							path={`${enums.PAGES_MEDIA_LIST}`}
							element={
								<>
									<NavBar />
									<MediaGallery />
								</>
							}
						/>
						<Route
							path={`${enums.PAGES_BOAT_LIST}`}
							element={
								<>
									<NavBar />
									<BateauHome />
								</>
							}
						/>
						<Route
							path={`${enums.PAGES_BOAT_ADD}`}
							element={
								<>
									<NavBar />
									<BateauHome />
								</>
							}
						/>
						<Route
							path={`${enums.PAGES_BOAT_EDIT}/:id`}
							element={
								<>
									<NavBar />
									<BateauHome />
								</>
							}
						/>
						<Route
							path="/configuration"
							element={
								<>
									<NavBar />
									<ConfigurationPanel />
								</>
							}
						/>
						<Route
							path={`${enums.PAGES_MESSAGE_LIST}`}
							element={
								<>
									<NavBar />
									<ContactList />
								</>
							}
						/>
						<Route
							path={`${enums.PAGES_USER_LIST}`}
							element={
								<>
									<NavBar />
									<UserHome />
								</>
							}
						/>
						<Route
							path={`${enums.PAGES_USER_ADD}`}
							element={
								<>
									<NavBar />
									<UserHome />
								</>
							}
						/>
						<Route
							path={`${enums.PAGES_USER_LIST}/:id`}
							element={
								<>
									<NavBar />
									<UserHome />
								</>
							}
						/>

						<Route
							path={`${enums.PAGES_TARIF_LIST}`}
							element={
								<>
									<NavBar />
									<GrilleTarifHome />
								</>
							}
						/>
						<Route
							path={`${enums.PAGES_DUREE_LIST}`}
							element={
								<>
									<NavBar />
									<GrilleTarifHome />
								</>
							}
						/>
						<Route
							path={`${enums.PAGES_DUREE_EDIT}/:id`}
							element={
								<>
									<NavBar />
									<GrilleTarifHome />
								</>
							}
						/>
						<Route
							path={`${enums.PAGES_DUREE_ADD}`}
							element={
								<>
									<NavBar />
									<GrilleTarifHome />
								</>
							}
						/>

						<Route
							path={`${enums.PAGES_TARIF_LIST1}`}
							element={
								<>
									<NavBar />
									<GrilleTarifHome />
								</>
							}
						/>
						<Route
							path={`${enums.PAGES_TARIF_LIST2}`}
							element={
								<>
									<NavBar />
									<GrilleTarifHome />
								</>
							}
						/>
						<Route
							path={`${enums.PAGES_TARIF_EDIT}/:id`}
							element={
								<>
									<NavBar />
									<GrilleTarifHome />
								</>
							}
						/>
						<Route
							path={`${enums.PAGES_TARIF_ADD}`}
							element={
								<>
									<NavBar />
									<GrilleTarifHome />
								</>
							}
						/>

						<Route
							path={`${enums.PAGES_SAISON_LIST}`}
							element={
								<>
									<NavBar />
									<GrilleTarifHome />
								</>
							}
						/>
						<Route
							path={`${enums.PAGES_SAISON_EDIT}/:id`}
							element={
								<>
									<NavBar />
									<GrilleTarifHome />
								</>
							}
						/>
						<Route
							path={`${enums.PAGES_SAISON_ADD}`}
							element={
								<>
									<NavBar />
									<GrilleTarifHome />
								</>
							}
						/>
						<Route
							path="*"
							element={
								<>
									<NavBar />
									<Dashboard />
								</>
							}
						/>
					</Routes>
				</div>
			</Router>
		</AuthProvider>
	);
};

export default App;
