import React, { useEffect, useState } from "react";
import {
	fetchParametres,
	updateParametre
} from "../api/configuration/configService";
import { Parametre } from "../api/configuration/configTypes";

const ConfigurationPanel: React.FC = () => {
	const [parametres, setParametres] = useState<Parametre[]>([]);
	const [loading, setLoading] = useState(true);
	const [editKey, setEditKey] = useState<string | null>(null);
	const [editValue, setEditValue] = useState<string>("");

	useEffect(() => {
		const loadParametres = async () => {
			try {
				const data = await fetchParametres();
				setParametres(data);
			} catch (error) {
				console.error("Failed to fetch parameters", error);
			} finally {
				setLoading(false);
			}
		};

		loadParametres();
	}, []);

	const handleEditClick = (key: string, value: string) => {
		setEditKey(key);
		setEditValue(value);
	};

	const handleSaveClick = async () => {
		if (editKey) {
			try {
				await updateParametre(editKey, { key: editKey, value: editValue });
				setParametres((prev) =>
					prev.map((param) =>
						param.key === editKey ? { key: editKey, value: editValue } : param
					)
				);
				setEditKey(null);
				setEditValue("");
			} catch (error) {
				console.error("Failed to update parameter", error);
			}
		}
	};

	if (loading) return <p>Loading...</p>;

	return (
		<div>
			<h1>Configuration</h1>
			<ul>
				{parametres.map((param) => (
					<li key={param.key}>
						<span>
							{param.key}: {param.value}
						</span>
						<button onClick={() => handleEditClick(param.key, param.value)}>
							Edit
						</button>
					</li>
				))}
			</ul>
			{editKey && (
				<div>
					<h2>Edit Parameter</h2>
					<input
						type="text"
						value={editValue}
						onChange={(e) => setEditValue(e.target.value)}
					/>
					<button onClick={handleSaveClick}>Save</button>
				</div>
			)}
		</div>
	);
};

export default ConfigurationPanel;
