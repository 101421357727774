import React from "react";

const MonocoqueIcon = ({ className }: { className?: string }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			className={`${className}`}
			viewBox="0 0 353 353"
		>
			<g
				id="Groupe_5"
				data-name="Groupe 5"
				transform="translate(19518 -277)"
			>
				<rect
					id="Rectangle_2"
					data-name="Rectangle 2"
					width="353"
					height="353"
					transform="translate(-19518 277)"
					fill="none"
				/>
				<g
					id="Groupe_4"
					data-name="Groupe 4"
					transform="translate(-296.856 -4.883)"
				>
					<path
						id="Tracé_1"
						data-name="Tracé 1"
						d="M-18146.377,564.854V639.97s1.119,3.548-2.115,4.357-26.133-16.914-35.555-35.8-14.6-43.672-14.6-43.672h52.27"
						transform="translate(-909.497 -38.644)"
						fill="none"
						stroke="currentColor"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="7"
					/>
					<path
						id="Tracé_3"
						data-name="Tracé 3"
						d="M-18135.557,593.267"
						transform="translate(-960 -38.644)"
						fill="none"
						stroke="currentColor"
						strokeLinecap="round"
						strokeWidth="7"
					/>
					<g
						id="Groupe_2"
						data-name="Groupe 2"
						transform="translate(-20 3.249)"
					>
						<path
							id="Tracé_5"
							data-name="Tracé 5"
							d="M-18147.578,517.079l6.439-24.607a7.719,7.719,0,0,1,3.34-2.781,23.349,23.349,0,0,1,5.965-1.391h34.117a14.82,14.82,0,0,1,4.535,1.391,8.5,8.5,0,0,1,2.861,2.781l6.441,24.607"
							transform="translate(-921.979 5.881)"
							fill="none"
							stroke="currentColor"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="7"
						/>
						<path
							id="Tracé_7"
							data-name="Tracé 7"
							d="M-18141.105,538.7h57.277"
							transform="translate(-924.706 -30.875)"
							fill="none"
							stroke="currentColor"
							strokeLinecap="round"
							strokeWidth="7"
						/>
						<path
							id="Tracé_8"
							data-name="Tracé 8"
							d="M-18103.65,515.12v13.914"
							transform="translate(-943.242 -20.939)"
							fill="none"
							stroke="currentColor"
							strokeLinecap="round"
							strokeWidth="7"
						/>
						<path
							id="Tracé_9"
							data-name="Tracé 9"
							d="M-18066.658,515.12v13.914"
							transform="translate(-961.148 -20.939)"
							fill="none"
							stroke="currentColor"
							strokeLinecap="round"
							strokeWidth="7"
						/>
					</g>
					<g
						id="Groupe_3"
						data-name="Groupe 3"
						transform="translate(-36.955 31.611)"
					>
						<path
							id="Tracé_10"
							data-name="Tracé 10"
							d="M-18085,534.109V353.278s7.3,23.874,23.961,53.843,29.715,42.922,42.668,66.034,6.1,34.541,6.1,34.541-3.3,5.451-16.254,15.611-32.51,16.9-32.51,16.9"
							transform="translate(-934 -73.006)"
							fill="none"
							stroke="currentColor"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="7"
						/>
						<path
							id="Tracé_11"
							data-name="Tracé 11"
							d="M-18130.121,532.629a601.09,601.09,0,0,0,25.4-74.161c11.176-41.4,19.3-91.432,19.3-91.432"
							transform="translate(-934 -38)"
							fill="none"
							stroke="currentColor"
							strokeLinecap="round"
							strokeWidth="7"
						/>
					</g>
					<path
						id="Tracé_12"
						data-name="Tracé 12"
						d="M-18198.646,564.854V639.97s-1.244,3.274,1.99,4.084,26.258-16.64,35.68-35.527,14.6-43.672,14.6-43.672h-52.27"
						transform="translate(-857.228 -38.644)"
						fill="none"
						stroke="currentColor"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="7"
					/>
				</g>
			</g>
		</svg>
	);
};

export default MonocoqueIcon;
