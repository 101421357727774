import React from "react";

const CatamaranIcon = ({ className }: { className?: string }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			className={`${className}`}
			viewBox="0 0 353 353"
		>
			<g
				id="Groupe_6"
				data-name="Groupe 6"
				transform="translate(18882 -277)"
			>
				<rect
					id="Rectangle_1"
					data-name="Rectangle 1"
					width="353"
					height="353"
					transform="translate(-18882 277)"
					fill="none"
				/>
				<g
					id="Groupe_1"
					data-name="Groupe 1"
					transform="translate(-620.354 -45.278)"
				>
					<path
						id="Tracé_1"
						data-name="Tracé 1"
						d="M-18162.377,564.854V639.97a8.678,8.678,0,0,1-4.9,3.557c-3.236.809-8.6-1.223-9.684-3.126a177.417,177.417,0,0,1-14.086-31.875c-6.008-18.886-7.6-43.672-7.6-43.672h220.91s6.795-.42,6.795,3.443v9.248"
						fill="none"
						stroke="currentColor"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="7"
					/>
					<path
						id="Tracé_2"
						data-name="Tracé 2"
						d="M-17975.736,596.041s-4.379,13.641-8.613,25.432-8.236,17.844-8.236,17.844-3.436,4-7.156,4.209-7.729-3.368-7.729-3.368V565.522"
						fill="none"
						stroke="currentColor"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="7"
					/>
					<path
						id="Tracé_3"
						data-name="Tracé 3"
						d="M-18159.6,593.267h24.045"
						fill="none"
						stroke="currentColor"
						strokeLinecap="round"
						strokeWidth="7"
					/>
					<path
						id="Tracé_4"
						data-name="Tracé 4"
						d="M-18117.061,593.267h6.268a19.012,19.012,0,0,1,5.754,1,17.344,17.344,0,0,1,4.838,2.974l4.523,3.716a18.105,18.105,0,0,0,3.586,1.946,19.667,19.667,0,0,0,4.059,1h5.787a21.107,21.107,0,0,0,4.027-1,25.992,25.992,0,0,0,3.971-1.946l6.047-4.7a8.523,8.523,0,0,1,2.854-1.993,17.633,17.633,0,0,1,4.234-1h53.643"
						fill="none"
						stroke="currentColor"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="7"
					/>
					<path
						id="Tracé_5"
						data-name="Tracé 5"
						d="M-18147.578,564.854l12.482-65.455a17.917,17.917,0,0,1,6.475-7.4c4.508-2.774,11.561-3.7,11.561-3.7h66.123a23.169,23.169,0,0,1,8.787,3.7,21.019,21.019,0,0,1,5.549,7.4l12.484,65.455"
						fill="none"
						stroke="currentColor"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="7"
					/>
					<path
						id="Tracé_6"
						data-name="Tracé 6"
						d="M-18135.557,515.12h101.266"
						fill="none"
						stroke="currentColor"
						strokeLinecap="round"
						strokeWidth="7"
					/>
					<path
						id="Tracé_7"
						data-name="Tracé 7"
						d="M-18141.105,538.7h112.363"
						fill="none"
						stroke="currentColor"
						strokeLinecap="round"
						strokeWidth="7"
					/>
					<path
						id="Tracé_8"
						data-name="Tracé 8"
						d="M-18103.65,515.12v24.045"
						fill="none"
						stroke="currentColor"
						strokeLinecap="round"
						strokeWidth="7"
					/>
					<path
						id="Tracé_9"
						data-name="Tracé 9"
						d="M-18066.658,515.12v24.045"
						fill="none"
						stroke="currentColor"
						strokeLinecap="round"
						strokeWidth="7"
					/>
					<path
						id="Tracé_10"
						data-name="Tracé 10"
						d="M-18085,488.3V353.278s15.006,46.806,31.383,76.862,35.557,44.7,35.557,44.7"
						fill="none"
						stroke="currentColor"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="7"
					/>
					<path
						id="Tracé_11"
						data-name="Tracé 11"
						d="M-18005.158,475.353h-80"
						fill="none"
						stroke="currentColor"
						strokeLinecap="round"
						strokeWidth="7"
					/>
				</g>
			</g>
		</svg>
	);
};

export default CatamaranIcon;
