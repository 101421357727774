import React, { useState } from "react";
import { useAuth } from "../context/AuthContext";

const Login = () => {
	const [email, setEmail] = useState<string>("");
	const [password, setPassword] = useState<string>("");
	const { user } = useAuth();
	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		handleLogin(email, password);
	};

	const { login, error } = useAuth();
	const handleLogin = async (email: string, password: string) => {
		try {
			const logged = await login(email, password);

			// Redirigez l'utilisateur vers la page d'accueil ou une autre page

			window.location.href = "/";
		} catch (error) {
			console.error("Erreur lors de la connexion :", error);
			alert("Email ou mot de passe incorrect");
		}
	};

	return (
		<div className="flex items-center justify-center min-h-screen bg-gray-100">
			<div className="w-full max-w-md p-8 bg-white shadow-md rounded-lg">
				<h2 className="flex flex-col  w-auto text-2xl font-bold text-center mb-6">
					<img
						className=""
						src={
							"https://punch-croisieres.com/assets/media/default-195x106.png"
						}
						alt=""
					/>
					Login
				</h2>
				{error && <p className="text-red-500 text-center mb-4">{error}</p>}
				<form onSubmit={handleSubmit}>
					<div className="mb-4">
						<label
							htmlFor="email"
							className="block text-sm font-medium text-gray-700"
						>
							Email
						</label>
						<input
							type="email"
							id="email"
							name="email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
							required
						/>
					</div>
					<div className="mb-6">
						<label
							htmlFor="password"
							className="block text-sm font-medium text-gray-700"
						>
							Password
						</label>
						<input
							type="password"
							id="password"
							name="password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
							required
						/>
					</div>
					<button
						type="submit"
						className="w-full px-4 py-2 bg-indigo-600 text-white font-semibold rounded-lg shadow-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
					>
						Log In
					</button>
				</form>
			</div>
		</div>
	);
};

export default Login;
