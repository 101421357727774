import React, { useEffect, useState } from "react";
import { fetchEscales } from "../../api/escales/escaleService";
import { Escale } from "../../api/escales/escaleTypes";
import moment from "moment";
import EscaleMenu from "./EscaleMenu";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import SafeImage from "../images/SafeImage";
import { checkImageExists } from "src/lib/functions";

const EscaleList = ({
	handleSelectEscale
}: {
	handleSelectEscale: (escale?: Escale) => void;
}) => {
	const navigate = useNavigate();
	const [escales, setEscales] = useState<Escale[]>([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const loadEscales = async () => {
			try {
				const data = (await fetchEscales()) as Escale[];
				setEscales(data);
			} catch (error: any) {
				debugger;
				if (error.response.data === "Failed to authenticate token.") {
					navigate("/login");
				}

				console.error("Failed to fetch escales", error);
			} finally {
				setLoading(false);
			}
		};

		loadEscales();
	}, []);

	const categ = ["Escale", "Actualité", "Page"];
	if (loading) return <p>Loading...</p>;

	return (
		<>
			<div className="grid grid-cols-2 w-full text-sm max-w-5xl h-full m-auto overflow-y-auto pb-10 mt-2 gap-3">
				{escales.map((escale) => {
					return (
						<React.Fragment key={escale.id}>
							<div
								className={`relative grid grid-rows-[auto_1fr_auto] shadow-md rounded-md items-center odd:bg-gray-200 even:bg-gray-50`}
							>
								<div className="flex h-full px-2 gap-2">
									<span className="whitespace-nowrap font-bold capitalize">
										lieu :
									</span>
									<span className="">
										{escale.type?.nom ? `${escale.type?.nom} de` : ""}{" "}
										{escale.lieu}
									</span>
								</div>
								<div className="flex flex-col h-full px-2">
									<span className="font-bold capitalize">Description :</span>
									<span className="px-2">{escale.description}</span>
								</div>
								<div className="flex w-full gap-2 p-2">
									<button
										className="grid grid-cols-[auto_1fr] rounded-md bg-indigo-600 px-3 py-2 mx-auto text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 whitespace-nowrap items-center justify-between w-full"
										onClick={() => {
											handleSelectEscale(escale);
										}}
									>
										<div className="">
											<PencilIcon className="w-4 h-4" />
										</div>
										<div className="">Modifier</div>
									</button>
									<button
										className="grid grid-cols-[auto_1fr] rounded-md bg-rose-600 px-3 py-2  mx-auto text-sm font-semibold text-white shadow-sm hover:bg-rose-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-rose-600 whitespace-nowrap items-center justify-between w-full"
										onClick={() => {}}
									>
										<div className="">
											<TrashIcon className="w-4 h-4" />
										</div>
										<div className="">Supprimer</div>
									</button>
								</div>
							</div>
						</React.Fragment>
					);
				})}
			</div>
		</>
	);
};

export default EscaleList;
