import axiosInstance from "../axiosConfig";
import { User } from "./userTypes";

// Récupérer tous les utilisateurs
export const fetchUsers = async () => {
	const response = await axiosInstance.get("/users");
	return response.data;
};

// Récupérer un utilisateur par ID
export const fetchUserById = async (id: number) => {
	const response = await axiosInstance.get(`/users/${id}`);
	return response.data;
};

// Créer un nouvel utilisateur
export const createUser = async (user: User) => {
	const response = await axiosInstance.post("/users", user);
	return response.data;
};

// Mettre à jour un utilisateur existant
export const updateUser = async (id: number, user: User) => {
	const response = await axiosInstance.put(`/users/${id}`, user);
	return response.data;
};

// Supprimer un utilisateur
export const deleteUser = async (id: number) => {
	await axiosInstance.delete(`/users/${id}`);
};
