import React, { useEffect, useState } from "react";
import { fetchTarifs } from "../../api/tarifs/tarifService";
import { Tarif } from "../../api/tarifs/tarifTypes";
import moment from "moment";
import TarifMenu from "./TarifMenu";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";

import { useAppSettingStore } from "src/services/useAppSettingStore";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as enums from "src/lib/enums";
import TarifList from "./TarifList";
import GrilleTarifMenu from "./GrilleTarifMenu";
import SaisonList from "./SaisonList";
import { Saison } from "src/api/saisons/saisonTypes";
import SaisonForm from "./SaisonForm";
import { fetchSaisons } from "src/api/saisons/saisonService";

function GrilleTarifHome() {
	const navigate = useNavigate();
	let { id } = useParams();

	const [currentPage] = useAppSettingStore((state) => [state.currentPage]);

	let location = useLocation();

	const [saisons, setSaisons] = useState<Saison[]>([]);

	// const [selectedTarif, setSelectedTarif] = useState<Tarif | undefined>(
	// 	tarifs.find((art) => id && art.id?.toString() === id)
	// );

	const selectedSaison = saisons.find((art) => id && art.id?.toString() === id);
	const [loading, setLoading] = useState(true);
	const handleSelectSaison = (saison?: Saison) => {
		if (saison) {
			navigate(`${enums.PAGES_SAISON_EDIT}/${saison.id}`);
		} else {
			navigate(enums.PAGES_SAISON_ADD);
		}
		//setSelectedTarif(tarif);
	};

	useEffect(() => {
		const loadSaisons = async () => {
			try {
				const data = (await fetchSaisons()) as Saison[];

				setSaisons(data);
			} catch (error: any) {
				debugger;
				if (error.response.data === "Failed to authenticate token.") {
					navigate("/login");
				}

				console.error("Failed to fetch tarifs", error);
			} finally {
				setLoading(false);
			}
		};

		loadSaisons();
	}, []);

	useEffect(() => {
		//setSelectedTarif(undefined);
	}, [location.pathname]);

	if (loading) return <p>Loading...</p>;

	return (
		<div className="flex flex-col w-full  h-full overflow-hidden">
			<GrilleTarifMenu />
			{location.pathname === enums.PAGES_TARIF_LIST ||
			location.pathname === enums.PAGES_TARIF_LIST1 ? (
				<TarifList grilleId={1} />
			) : location.pathname === enums.PAGES_TARIF_LIST2 ? (
				<TarifList grilleId={2} />
			) : location.pathname === enums.PAGES_SAISON_LIST ? (
				<SaisonList handleSelectSaison={handleSelectSaison} />
			) : location.pathname.includes(enums.PAGES_SAISON_EDIT) ? (
				<SaisonForm selectedSaison={selectedSaison} />
			) : location.pathname.includes(enums.PAGES_SAISON_ADD) ? (
				<SaisonForm selectedSaison={undefined} />
			) : (
				""
			)}
		</div>
	);
}

export default GrilleTarifHome;
