/* eslint-disable no-sequences */
/* eslint-disable @typescript-eslint/no-explicit-any */

import moment, { Moment } from "moment";
import * as types from "src/lib/types";
import * as enums from "src/lib/enums";
//import CryptoJS from "crypto-js";
//import { execFile } from "child_process";
import tailwindColors from "tailwindcss/colors";
import axios from "axios";

moment.locale("fr", enums.momentFR);

const scrt = (process.env.VITE_AUTH_TO_STATS ?? "undefined").substring(0, 5);

export const capitalize = (text: string) => {
	return text.charAt(0).toUpperCase() + text.slice(1);
};

export const stringToColor = (text: string) => {
	let hash = 0;
	let i;

	/* eslint-disable no-bitwise */
	for (i = 0; i < text.length; i += 1) {
		hash = text.charCodeAt(i) + ((hash << 5) - hash);
	}

	let color = "#";

	for (i = 0; i < 3; i += 1) {
		const value = (hash >> (i * 8)) & 0xff;
		color += `00${value.toString(16)}`.slice(-2);
	}
	/* eslint-enable no-bitwise */

	return color;
};

export const stringAvatar = (name: string, size?: number) => {
	try {
		return {
			sx: {
				bgcolor: stringToColor(name),
				width: `${size ?? 30}px`,
				height: `${size ?? 30}px`,
				fontSize: "0.9rem"
			},
			children: `${name.split(" ")[0][0].toUpperCase() ?? ""}${
				name.split(" ")[1][0].toUpperCase() ?? ""
			}`
		};
	} catch (error) {
		return {
			sx: {
				bgcolor: stringToColor(name),
				width: `${size ?? 30}px`,
				height: `${size ?? 30}px`,
				fontSize: "0.9rem"
			},
			children: `UZ`
		};
	}
};

export const randomIntFromInterval = (min: number, max: number) => {
	// min and max included
	return Math.floor(Math.random() * (max - min + 1) + min);
};

export const randomMomentDate = (start: Moment, end: Moment) => {
	try {
		const year = Math.floor(
			Math.random() *
				(parseInt(start.format("YYYY")) - parseInt(end.format("YYYY")) + 1) +
				parseInt(end.format("YYYY"))
		);
		const month = Math.floor(
			Math.random() *
				(parseInt(start.format("MM")) - parseInt(end.format("MM")) + 1) +
				parseInt(end.format("MM"))
		);
		const day = Math.floor(Math.random() * (1 - 28 + 1) + 28);

		const nMoment = moment(
			`${year}${get2IntDate(month)}${get2IntDate(day)}`,
			"YYYYMMDD"
		);

		return nMoment;
	} catch (error) {
		//todo catch error
		debugger;
	}
	return moment();
};
export const randomNumberDate = (start: Moment, end: Moment) => {
	return randomMomentDate(start, end).format("YYYY-MM-DD");
};

export const get2IntDate = (element: number) => {
	if (element === null) {
		console.log("get2IntDate:Error on element value (=null)");
	}

	return element.toString().length === 2
		? element.toString()
		: "0" + element.toString();
};
export const get4IntDate = (element: number) => {
	if (element === null) {
		console.log("get2IntDate:Error on element value (=null)");
	}

	return element.toString().length === 4
		? element.toString()
		: ("0" + element.toString()).length === 4
		? "0" + element.toString()
		: ("00" + element.toString()).length === 4
		? "00" + element.toString()
		: "000" + element.toString();
};

export const getMonthDays = (month: number, year: number): Moment[][] => {
	const currentDate = moment(`${year}/${month}/${1}`, "YYYY/M/D");
	const firstDayOfMonth = currentDate.clone().startOf("month").day();
	const daysInMonth = currentDate.clone().daysInMonth();

	const daysInPreviousMonth = currentDate
		.clone()
		.add(-1, "month")
		.daysInMonth();

	// Ajuster pour commencer par un lundi (0 = dimanche, 1 = lundi, ...)
	const startDay = firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1;

	const calendar: Moment[][] = [];
	let dayCounter = 1;
	let prevMonthCounter = daysInPreviousMonth - startDay;

	for (let week = 0; week < 6; week++) {
		const weekDays = [];

		for (let day = 0; day < 7; day++) {
			if (week === 0 && day < startDay) {
				// Jours du mois précédent
				prevMonthCounter++;
				const dt = prevMonthCounter;
				const newDate = currentDate.clone().add(-1, "month");
				const date = newDate.set("date", dt);

				weekDays.push(moment(date.clone().format("DD/MM/YYYY"), "DD/MM/YYYY"));
			} else if (dayCounter > daysInMonth) {
				// Jours du mois suivant
				const newDate = currentDate.add(1, "day");
				const date = newDate;
				weekDays.push(moment(date.clone().format("DD/MM/YYYY"), "DD/MM/YYYY"));
				dayCounter++;
			} else {
				// Jours du mois courant
				const dt = dayCounter++;
				const date = currentDate.set("date", dt);

				weekDays.push(moment(date.clone().format("DD/MM/YYYY"), "DD/MM/YYYY"));
			}
		}

		calendar.push(weekDays);
	}

	return calendar;
};

export const generateUniqueID = (params: {
	length?: number;
	prefix?: string;
	isDateUnique?: boolean;
}) => {
	const characters =
		"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	let id = params.prefix ?? "";

	for (let i = 0; i < (params.length ?? 10); i++) {
		const randomIndex = Math.floor(Math.random() * characters.length);
		id += characters.charAt(randomIndex);
	}

	if (params.isDateUnique) {
		const date = moment().format("DDMMYYYY");
		id += date;
	}
	return id;
};
export const generateUniqueNumericID = (params: {
	length?: number;
	isDateUnique?: boolean;
}) => {
	const characters = "0123456789";
	let id = "";

	for (let i = 0; i < (params.length ?? 10); i++) {
		const randomIndex = Math.floor(Math.random() * characters.length);
		id += characters.charAt(randomIndex);
	}
	if (params.isDateUnique) {
		const date = moment().format("DDMMYYYY");
		id += date;
	}
	return parseInt(id);
};

//const express = require("express");
//import "dotenv/config";
//import config from "src/config";

const prox = "";
//prox = "https://cors.bridged.cc/";
//prox ="https://cors-anywhere.herokuapp.com/";

const gameVersion = "1.0.0.0";
const storageString = "storageString";

const REACT_APP_STAT_API_URL = "https://arawak.ovh/api/geo-stats"; //process.env.REACT_APP_STAT_API_URL;
const REACT_APP_AUTH_TO_STATS =
	"a9f901d0112eb8f6ee16d22ef74d4410b0cdd2b44a46a26f1abce375c584adc8327f59d248e7474fdd4b92b6a692221a13d97554faf59811243cf361bf0e0f04c0fe3cc0feac793d17401810341f171b5448ed856606a4ee66e4e0bd7874e94c0db640802e70857a1e1c4993c3a5f85644f81d09bfaec07e59b84d53f0e7597f"; //process.env.REACT_APP_AUTH_TO_STATS;
const REACT_APP_IP_DETAIL_API_URL = "https:ipinfo.io/"; //process.env.REACT_APP_IP_DETAIL_API_URL;
const REACT_APP_IP_API_TOKEN = "507be5a6a1835f"; //process.env.REACT_APP_IP_API_TOKEN;
const REACT_APP_IP_API_URL = "https://www.cloudflare.com/cdn-cgi/trace"; //process.env.REACT_APP_IP_API_URL;

/*const scrt = REACT_APP_AUTH_TO_STATS
	? REACT_APP_AUTH_TO_STATS.substring(0, 5)
	: "";*/

//var CryptoJS = require("crypto-js");

let _cache: any = null;
const disableRecheck = true;

export const getRandomArbitrary = (min: number, max: number) => {
	return Math.random() * (max - min) + min;
};

const newGame = {};
const getGoodStrorageContent = () => {
	const localStore = localStorage.getItem(storageString);

	let jetriebien = null;

	try {
		jetriebien = localStore /*decrypt(localStore).data*/;
	} catch (error) {
		//todo catch error and report
	}

	let jsonValue: any;
	if (jetriebien) {
		try {
			jsonValue = jetriebien;
		} catch (error) {}
	} else {
		localStorage.setItem(
			storageString,
			/*encrypt(newGame)*/ JSON.stringify(newGame)!
		);
		jsonValue = newGame;
	}

	// console.log(JSON.stringify(jsonValue));
	return jsonValue;
};

const setStorage = (value: any) => {
	//console.log("setStorage");
	const date = new Date();
	value.update =
		date.getFullYear() + "_" + date.getMonth() + "_" + date.getDate();

	_cache = value;
	const enc = value; //encrypt(value);
	localStorage.setItem(storageString, enc!);
};

const getJeTrieBien = () => {
	if (!_cache) {
		const jsonValue = getGoodStrorageContent();
		if (!jsonValue || !jsonValue.version || jsonValue.version !== gameVersion) {
			setVersion(gameVersion);
		}
		_cache = jsonValue;
		return jsonValue;
	} else {
		//console.log(_cache);
		return _cache;
	}
};

export const getAllDetails = () => {
	const jetriebien = getJeTrieBien();
	return JSON.stringify({ ...jetriebien });
};

const setVersion = (versionValue: string) => {
	const jetriebien = getGoodStrorageContent();
	setStorage({
		...jetriebien,
		version: versionValue,
		...newGame
	});
};

export const setScore = (score: any) => {
	const jetriebien = getJeTrieBien();
	setStorage({ ...jetriebien, score: score });
};
export const savePlaceData = (
	data: {
		campagne: any;
		href: string;
		search: string;
		url: string;
		format: string;
		countryCode: any;
		countryName: any;
	} | null
) => {
	const jetriebien = getJeTrieBien();
	if (data) {
		const ecrpt = data;
		setStorage({
			...jetriebien,
			placeData: {
				debut: new Date(),
				value: ecrpt
			}
		});
	} else {
		setStorage({
			...jetriebien,
			placeData: null
		});
	}
};

export const getRegion = () => {
	const jetriebien = getJeTrieBien();
	if (!jetriebien.placeData) {
		return null;
	}

	const obj = jetriebien.placeData.value;
	return obj.countryCode;
};

export const getMobileType = () => {
	const jetriebien = getJeTrieBien();
	return parseInt(jetriebien.mobileType);
};

export const loadPlaceData = () => {
	const jetriebien = getJeTrieBien();
	let value = null;
	if (jetriebien.placeData && jetriebien.placeData !== "") {
		const dt1 = new Date(jetriebien.placeData.debut);
		const dt2 = new Date();
		const diffBet = DateDiff.inMinutes(dt1, dt2);
		if (diffBet > 20 || !value) {
			savePlaceData(null);
			return null;
		}
		try {
			value = jetriebien.placeData.value;
		} catch (error) {}
	}
	return value;
};

/* ---------------------------------------------- */

export const getCurrCagnotte = () => {
	//console.log("called to get charity");
	getData(true);
};

let ipsnippet: any;

export const getData = (isGET = false) => {
	const isCalledWithGET = typeof isGET === "boolean" ? isGET : false;
	const cachedData = loadPlaceData();
	if (!cachedData) {
		try {
			const url = prox + REACT_APP_IP_API_URL;

			axios
				.get(url)
				.then(function (response: any) {
					const data = response.data
						.trim()
						.split("\n")
						.reduce(function (obj: { [x: string]: any }, pair: any) {
							pair = pair.split("=");
							return (obj[pair[0]] = pair[1]), obj;
						}, {});
					//console.log(data);
					getIpdetails(data, isCalledWithGET);
				})
				.catch((err: any) => {
					//todo catch error and report
				});
		} catch (error) {
			//todo catch error and report
		}
	} else {
		workData(cachedData, isCalledWithGET);
	}
};

const getIpdetails = (
	ipShortDetail: { IPv4: string; ip: string },
	isGET = false
) => {
	const isCalledWithGET = typeof isGET === "boolean" ? isGET : false;
	if (ipShortDetail) {
		ipsnippet = ipShortDetail;
		if (disableRecheck) {
			workData(null, isCalledWithGET);
		} else {
			const ipv4 = ipShortDetail.IPv4 ? ipShortDetail.IPv4 : ipShortDetail.ip;
			const ipDetailApiUri =
				prox +
				REACT_APP_IP_DETAIL_API_URL +
				ipv4 +
				"?token=" +
				REACT_APP_IP_API_TOKEN;
			axios
				.get(
					ipDetailApiUri /*, {
					headers: fetchHeaders,
				}*/
				)
				.then((response: any) => {
					const resp = response.json();
					resp.then((data: any) => {
						workData(data, isCalledWithGET);
						//	setIpDetail(db_entry);
					});
				})
				.catch((err: any) => {
					//todo catch error and report
				});
		}
	}
};

const workData = (data: null, isCalledWithGET: boolean) => {
	savePlaceData(getCampagneValuesFromUrl(null, null, data));
	if (isCalledWithGET) {
		//console.log("getStatData called");
		getStatData(enums.CAMPAGNE_CITEO_CHARITY, data);
	} else {
		postStatData(enums.CAMPAGNE_CITEO_STAT, data);
	}
};

export const getStatData = (campagne: number, data: any) => {
	const queryParams = new URLSearchParams(window.location.search);
	const parsedData = getCampagneValuesFromUrl(campagne, queryParams, data);
	const db_entry = encodeURIComponent(
		JSON.stringify(parsedData) /*encrypt(parsedData)*/!
	);

	axios
		.get(prox + REACT_APP_STAT_API_URL + "?total=1&data=" + db_entry, {
			headers: {
				/*...fetchHeaders,*/
				"content-type": "application/json",
				Authorization: `Bearer ${REACT_APP_AUTH_TO_STATS}`
			}
		})
		.then((response: any) => {
			//todo job
			//setCagnotte(response.data * variables.WINNER_PRICE);
		})
		.catch((err: any) => {
			//todo catch error and report
		});
};

export const postStatData = (campagne: number, data: any) => {
	const queryParams = new URLSearchParams(window.location.search);
	const parsedData = getCampagneValuesFromUrl(campagne, queryParams, data);
	const db_entry = { data: parsedData /* encrypt(parsedData)*/ };

	const requestOptions = {
		method: "POST",
		headers: {
			/*...fetchHeaders,*/
			"content-type": "application/json",
			Authorization: `Bearer ${REACT_APP_AUTH_TO_STATS}`
		}
	};
	axios
		.post(prox + REACT_APP_STAT_API_URL, db_entry, requestOptions)
		.then((response: any) => {
			//todo do something
		})
		.catch((err: any) => {
			//todo catch error and report
		});
};
function getCampagneValuesFromUrl(
	campagne?: number | null,
	qparams?: URLSearchParams | null,
	ipParams?: any
) {
	const goeS = {
		campagne: campagne,
		href: window.location.href,
		search: window.location.search,
		url: window.location.host,
		format: getMobileTypeName(),
		countryCode: ipsnippet.cc ? ipsnippet.cc : ipsnippet.loc,
		countryName: ipsnippet.country
		/*latitude: ipParams.loc.split(",")[0],
		longitude: ipParams.loc.split(",")[1],
		timezone: ipParams.timezone,
		city: ipParams.city,
		region: ipParams.region,*/
	};
	return goeS;
}

const getMobileTypeName = () => {
	if (/iphone/i.test(navigator.userAgent.toLowerCase())) {
		return "iphone";
	} else if (/ipad/i.test(navigator.userAgent.toLowerCase())) {
		return "ipad";
	} else if (/ipod/i.test(navigator.userAgent.toLowerCase())) {
		return "ipod";
	} else if (/android/i.test(navigator.userAgent.toLowerCase())) {
		return "android";
	} else if (/blackberry/i.test(navigator.userAgent.toLowerCase())) {
		return "blackberry";
	} else if (/mini/i.test(navigator.userAgent.toLowerCase())) {
		return "mini";
	} else if (/windows\sce/i.test(navigator.userAgent.toLowerCase())) {
		return "windows";
	} else if (/palm/i.test(navigator.userAgent.toLowerCase())) {
		return "palm";
	} else if (/CrOS/i.test(navigator.userAgent.toLowerCase())) {
		return "Chromebook";
	} else if (/Macintosh/i.test(navigator.userAgent.toLowerCase())) {
		return "PC Mac";
	} else if (/Linux/i.test(navigator.userAgent.toLowerCase())) {
		return "PC Linux";
	} else if (/Xbox/i.test(navigator.userAgent.toLowerCase())) {
		return "Xbox";
	} else if (/Nintendo/i.test(navigator.userAgent.toLowerCase())) {
		return "Nintendo";
	} else if (/Windows NT/i.test(navigator.userAgent.toLowerCase())) {
		return "PC Windows";
	} else if (/CrKey/i.test(navigator.userAgent.toLowerCase())) {
		return "Chromecast";
	} else if (/AppleTV/i.test(navigator.userAgent.toLowerCase())) {
		return "AppleTV";
	} else if (/PlayStation/i.test(navigator.userAgent.toLowerCase())) {
		return "PlayStation";
	} else if (
		/Googlebot/i.test(navigator.userAgent.toLowerCase()) ||
		/bingbot/i.test(navigator.userAgent.toLowerCase()) ||
		/Yahoo/i.test(navigator.userAgent.toLowerCase())
	) {
		return "Bot";
	}

	return "autre";
};
const DateDiff = {
	inMinutes: function (d1: Date, d2: Date) {
		const t2: number = d2.getTime();
		const t1: number = d1.getTime();

		return parseInt("" + (t2 - t1) / 1000);
	},

	inHours: function (d1: { getTime: () => any }, d2: { getTime: () => any }) {
		const t2 = d2.getTime();
		const t1 = d1.getTime();

		return parseInt("" + (t2 - t1) / (3600 * 1000));
	},

	inDays: function (d1: { getTime: () => any }, d2: { getTime: () => any }) {
		const t2 = d2.getTime();
		const t1 = d1.getTime();

		return parseInt("" + (t2 - t1) / (24 * 3600 * 1000));
	},

	inWeeks: function (d1: { getTime: () => any }, d2: { getTime: () => any }) {
		const t2 = d2.getTime();
		const t1 = d1.getTime();

		return parseInt("" + (t2 - t1) / (24 * 3600 * 1000 * 7));
	},

	inMonths: function (
		d1: { getFullYear: () => any; getMonth: () => any },
		d2: { getFullYear: () => any; getMonth: () => any }
	) {
		const d1Y = d1.getFullYear();
		const d2Y = d2.getFullYear();
		const d1M = d1.getMonth();
		const d2M = d2.getMonth();

		return d2M + 12 * d2Y - (d1M + 12 * d1Y);
	},

	inYears: function (
		d1: { getFullYear: () => number },
		d2: { getFullYear: () => number }
	) {
		return d2.getFullYear() - d1.getFullYear();
	}
};
/*
const encrypt = (data: any) => {
	if (!data || data === "") return null;
	const result: string = CryptoJS.AES.encrypt(
		JSON.stringify({ data }),
		scrt
	).toString();
	return result;
};

const decrypt = (data: string | null) => {
	if (!data || data === "") return null;
	const decrypted = CryptoJS.AES.decrypt(data, scrt);
	const decStr = decrypted.toString(CryptoJS.enc.Utf8);
	return JSON.parse(decStr);
};*/

export const sort_by = (
	field: string,
	reverse: number,
	primer: (val: any) => void
) => {
	const key = primer
		? function (x: any) {
				return primer(x[field]);
		  }
		: function (x: any) {
				return x[field];
		  };

	reverse = !reverse ? 1 : -1;

	return function (a: any, b: any) {
		return (
			(a = key(a)),
			(b = key(b)),
			reverse * ((((a as any) > b) as any) - (((b as any) > a) as any))
		);
	};
};

export const SortByName = (a: any, b: any, order?: "asc" | "desc") => {
	const _order = order && order === "desc" ? -1 : 1;
	if (a.name < b.name) {
		return -1 * _order;
	}
	if (a.name > b.name) {
		return 1 * _order;
	}
	return 0;
};

export const SortByOrder = (a: any, b: any) => {
	if (a.order < b.order) {
		return -1;
	}
	if (a.order > b.order) {
		return 1;
	}
	return 0;
};
export const SortByDateRdv = (a: any, b: any, order?: "asc" | "desc") => {
	const _order = order && order === "desc" ? -1 : 1;
	const a_momentRdv = moment(a.dateRdv, "DD/MM/YYYY HH:mm").format(
		"YYYYMMDDHHmmss"
	);
	const b_momentRdv = moment(b.dateRdv, "DD/MM/YYYY HH:mm").format(
		"YYYYMMDDHHmmss"
	);

	if (a_momentRdv < b_momentRdv) {
		return -1 * _order;
	}
	if (a_momentRdv > b_momentRdv) {
		return 1 * _order;
	}
	return 0;
};

export const SortRemplaByDateDebut = (
	a: any,
	b: any,
	order?: "asc" | "desc"
) => {
	const _order = order && order === "desc" ? -1 : 1;
	const a_momentRdv = a.date_debut
		? parseInt(moment(a.date_debut, "YYYY-MM-DD").format("YYYYMMDD"))
		: 0;
	const b_momentRdv = b.date_debut
		? parseInt(moment(b.date_debut, "YYYY-MM-DD").format("YYYYMMDD"))
		: 0;

	if (a_momentRdv < b_momentRdv) {
		return -1 * _order;
	}
	if (a_momentRdv > b_momentRdv) {
		return 1 * _order;
	}
	return 0;
};

export const SortBySubFirstName = (a: any, b: any) => {
	if (a.userData.first_name < b.userData.first_name) {
		return -1;
	}
	if (a.userData.first_name > b.userData.first_name) {
		return 1;
	}
	return 0;
};

export const Date2Days = (yy: number, mm: number, dd: number) => {
	let bis, zy, zm;
	if (mm > 2) {
		bis = Math.floor(yy / 4) - Math.floor(yy / 100) + Math.floor(yy / 400);
		zy = Math.floor(yy * 365 + bis);
		zm = (mm - 1) * 31 - Math.floor(mm * 0.4 + 2.3);
		return zy + zm + dd;
	} else {
		bis =
			Math.floor((yy - 1) / 4) -
			Math.floor((yy - 1) / 100) +
			Math.floor((yy - 1) / 400);
		zy = Math.floor(yy * 365 + bis);
		return zy + (mm - 1) * 31 + dd;
	}
};

export const DefDateDay = (yy: number, mm: number, dd: number) => {
	return Math.floor((Date2Days(yy, mm, dd) - 2) % 7) + 1;
};

export const titleCase = (str: string) => {
	if (str !== "") {
		const splitStr = str.toLowerCase().split(" ");
		for (let i = 0; i < splitStr.length; i++) {
			// You do not need to check if i is larger than splitStr length, as your for does that for you
			// Assign it back to the array
			splitStr[i] =
				splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
		}
		// Directly return the joined string
		return splitStr.join(" ");
	}
	return str;
};
export const scrollParentToChild = (
	parentQuerySelector: string,
	childQuerySelector: string
) => {
	const child = document.querySelector(childQuerySelector);
	const parent = document.querySelector(parentQuerySelector);
	// Where is the parent on page
	const parentRect = parent!.getBoundingClientRect();
	// What can you see?
	const parentViewableArea = {
		height: parent!.clientHeight,
		width: parent!.clientWidth
	};
	if (child) {
		// Where is the child
		const childRect = child.getBoundingClientRect();
		// Is the child viewable?
		const isViewable =
			childRect.top >= parentRect.top &&
			childRect.top <= parentRect.top + parentViewableArea.height;

		// if you can't see the child try to scroll parent
		if (!isViewable) {
			// scroll by offset relative to parent
			parent!.scrollTo({
				top: childRect.top + parent!.scrollTop - parentRect.top,
				behavior: "smooth"
			});

			//	parent.scrollTop = childRect.top + parent.scrollTop - parentRect.top;
		}
	}
};

export const exportToPdf = (base64String: string, filename: string) => {
	const byteCharacters = atob(base64String);
	const byteNumbers = new Array(byteCharacters.length);
	for (let i = 0; i < byteCharacters.length; i++) {
		byteNumbers[i] = byteCharacters.charCodeAt(i);
	}
	const byteArray = new Uint8Array(byteNumbers);
	const pdfBlob = new Blob([byteArray], { type: "application/pdf" });
	const pdfUrl = URL.createObjectURL(pdfBlob);
	const downloadLink = document.createElement("a");
	downloadLink.href = pdfUrl;
	downloadLink.download = filename;
	document.body.appendChild(downloadLink);
	downloadLink.click();
	document.body.removeChild(downloadLink);
};

export function pdfToBase64(file: File): Promise<string> {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			const base64String = reader.result?.toString().split(",")[1];
			if (base64String) {
				resolve(base64String);
			} else {
				reject(new Error("Impossible de lire le fichier PDF."));
			}
		};
		reader.onerror = () => {
			reject(new Error("Erreur lors de la lecture du fichier PDF."));
		};
	});
}

export const getStringMemorySize = (str: string) => {
	const len = str.length;
	let bytes = 0,
		codePoint,
		next,
		i;

	for (i = 0; i < len; i++) {
		codePoint = str.charCodeAt(i);

		// Lone surrogates cannot be passed to encodeURI
		if (codePoint >= 0xd800 && codePoint < 0xe000) {
			if (codePoint < 0xdc00 && i + 1 < len) {
				next = str.charCodeAt(i + 1);

				if (next >= 0xdc00 && next < 0xe000) {
					bytes += 4;
					i++;
					continue;
				}
			}
		}

		bytes += codePoint < 0x80 ? 1 : codePoint < 0x800 ? 2 : 3;
	}

	return bytes;
};

export const getShortStringMemorySizeOctet = (str: string) => {
	const octet = parseInt((getStringMemorySize(str) / 8).toString());
	if (octet.toString().length < 4) {
		return `${parseInt(octet.toString())} o`;
	} else if (octet.toString().length < 7) {
		return `${parseInt((octet / 1000).toString())} ko`;
	} else if (octet.toString().length < 10) {
		return `${parseInt((octet / 1000000).toString())} Mo`;
	} else {
		return `${parseInt((octet / 1000000000).toString())} Go`;
	}
};
export const getShortStringMemorySizeByte = (str: string) => {
	const octet = parseInt(getStringMemorySize(str).toString());
	if (octet.toString().length < 4) {
		return `${parseInt(octet.toString())} B`;
	} else if (octet.toString().length < 7) {
		return `${parseInt((octet / 1000).toString())} kB`;
	} else if (octet.toString().length < 10) {
		return `${parseInt((octet / 1000000).toString())} MB`;
	} else {
		return `${parseInt((octet / 1000000000).toString())} GB`;
	}
};

export const getAgeFromBDay = (dateNaissance: Moment) => {
	const patientAge = moment.duration(moment().diff(dateNaissance));

	const ageInDays = patientAge.asDays();
	const ageInMonths = patientAge.asMonths();
	const ageInYears = patientAge.asYears();

	let unitToShow = "";
	let ageToShow;
	let stade = 1;

	if (ageInYears > 1) {
		ageToShow = parseInt(ageInYears.toString());
		unitToShow = "ans";
		if (ageToShow > 2) {
			stade = 3;
		} else {
			stade = 2;
		}
	} else if (ageInMonths > 1) {
		ageToShow = parseInt(ageInMonths.toString());
		unitToShow = "mois";
		stade = 2;
	} else {
		ageToShow = parseInt(ageInDays.toString());
		unitToShow = "jours";
		stade = 1;
	}

	const ageStr = `${ageToShow} ${unitToShow}`;
	return {
		ageInDays,
		ageInMonths,
		ageInYears,
		ageStr
	};
};

export const checkImageExists = async (url: string) => {
	const resp: boolean = await new Promise((resolve) => {
		const img = new Image();
		img.src = url;

		img.onload = () => resolve(true);
		img.onerror = () => resolve(false);
	});

	return resp;
};
