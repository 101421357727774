import React, { useEffect, useState } from "react";

import { Bateau } from "src/api/bateaux/bateauTypes";
import { deleteBateau, fetchBateaux } from "src/api/bateaux/bateauService";
import {
	DocumentIcon,
	PencilIcon,
	PlusIcon,
	TrashIcon
} from "@heroicons/react/24/solid";
import moment from "moment";
import { checkImageExists } from "src/lib/functions";
import SafeImage from "../images/SafeImage";
import BateauResume from "./BateauResume";
import CatamaranIcon from "../images/CatamaranIcon";
import MonocoqueIcon from "../images/MonocoqueIcon";
import RentIcon from "../images/RentIcon";
import AnnonceIcon from "../images/AnnonceIcon";
import { deleteMedia } from "src/api/medias/mediaService";
import { response } from "express";

const BateauList = ({
	handleSelectBateau
}: {
	handleSelectBateau: (bateau?: Bateau) => void;
}) => {
	const [bateaux, setBateaux] = useState<Bateau[]>([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<string | null>(null);
	const [showDetail, setShowDetail] = useState<boolean>(true);
	const [typeBateau, setTypeBateau] = useState<string | null>(null);
	const [grilleBateau, setGrilleBateau] = useState<string | null>(null);
	const [imageFilter, setImageFilter] = useState<string | null>(null);
	useEffect(() => {
		const loadBateaux = async () => {
			try {
				const data = await fetchBateaux();
				setBateaux(data);
			} catch (err) {
				setError("Failed to load bateaux");
			} finally {
				setLoading(false);
			}
		};

		loadBateaux();
	}, []);

	if (loading) return <p>Loading...</p>;
	if (error) return <p>{error}</p>;

	return (
		<div className="grid grid-rows-[auto_1fr] h-full w-full overflow-hidden">
			<div className="flex flex-col md:flex-row gap-2 text-xs p-2">
				<div className="flex flex-row gap-2 items-center ">
					<div className="font-bold whitespace-nowrap min-w-[80px]">
						Affichage :
					</div>
					<div className="flex w-full flex-row gap-2  items-center">
						<button
							className={`p-1 rounded-md  ${
								showDetail ? "bg-blue-400 text-white" : "bg-gray-100"
							}`}
							onClick={() => {
								setShowDetail(true);
							}}
						>
							détaillé
						</button>
						<button
							className={`p-1 rounded-md  ${
								!showDetail ? "bg-blue-400 text-white" : "bg-gray-100"
							}`}
							onClick={() => {
								setShowDetail(false);
							}}
						>
							{" "}
							résumé
						</button>
						<div className=""></div>
					</div>
				</div>
				<div className="flex  flex-row gap-2 items-center ">
					<div className="font-bold whitespace-nowrap min-w-[80px]">Type :</div>
					<div className="flex w-full flex-row gap-2  items-center">
						<button
							className={`p-1 rounded-md  ${
								typeBateau ? "bg-gray-100" : "bg-blue-400 text-white"
							}`}
							onClick={() => {
								setTypeBateau(null);
							}}
						>
							Tout
						</button>
						<button
							className={`p-1 rounded-md  ${
								typeBateau === "monocoque"
									? "bg-blue-400 text-white"
									: "bg-gray-100"
							}`}
							onClick={() => {
								setTypeBateau("monocoque");
							}}
						>
							<div className="flex flex-row gap-2">
								<div className="">
									<MonocoqueIcon className="x-6 w-6" />
								</div>
								<div className="">monocoque</div>
							</div>
						</button>
						<button
							className={`p-1 rounded-md  ${
								typeBateau === "catamaran"
									? "bg-blue-400 text-white"
									: "bg-gray-100"
							}`}
							onClick={() => {
								setTypeBateau("catamaran");
							}}
						>
							<div className="flex flex-row gap-2">
								<div className="">
									<CatamaranIcon className="x-6 w-6" />
								</div>
								<div className="">catamaran</div>
							</div>
						</button>
						<div className=""></div>
					</div>
				</div>
				<div className="flex  flex-row gap-2 items-center">
					<div className="font-bold whitespace-nowrap min-w-[80px]">
						Grille :
					</div>
					<div className="flex w-full flex-row gap-2  items-center">
						<button
							className={`p-1 rounded-md  ${
								grilleBateau ? "bg-gray-100" : "bg-blue-400 text-white"
							}`}
							onClick={() => {
								setGrilleBateau(null);
							}}
						>
							Tout
						</button>
						<button
							className={`p-1 rounded-md  ${
								grilleBateau === "courte durée"
									? "bg-blue-400 text-white"
									: "bg-gray-100"
							}`}
							onClick={() => {
								setGrilleBateau("courte durée");
							}}
						>
							courte durée
						</button>
						<button
							className={`p-1 rounded-md  ${
								grilleBateau === "longue durée"
									? "bg-blue-400 text-white"
									: "bg-gray-100"
							}`}
							onClick={() => {
								setGrilleBateau("longue durée");
							}}
						>
							longue durée
						</button>
						<div className=""></div>
					</div>
				</div>
			</div>
			<div className="flex flex-col h-full overflow-y-auto p-2">
				<div className="flex flex-col w-full">
					<div className="grid grid-cols-1 sm:grid-cols-2  md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-6 gap-4">
						{bateaux.length === 0 ? (
							<p>No media available</p>
						) : (
							bateaux
								.sort(
									(a, b) =>
										// parseInt(moment(b.created_at).format("YYYYMMDD")) -
										// parseInt(moment(a.created_at).format("YYYYMMDD"))
										a.taille - b.taille
								)
								.filter(
									(boat) =>
										//((imageFilter && img.type === imageFilter) || !imageFilter) &&
										((typeBateau &&
											boat.gamme?.nom_fr.toLocaleLowerCase() === typeBateau) ||
											!typeBateau) &&
										((grilleBateau &&
											boat.grilles?.find(
												(grille) =>
													grille.nom.toLocaleLowerCase() === grilleBateau
											)) ||
											!grilleBateau)
								)
								.map((bateau) => {
									return (
										<div
											key={bateau.id}
											className=" shadow-[0_0px_10px_1px_rgba(0,0,0,0.3)] rounded-md relative grid grid-rows-[auto_auto_auto_1fr_auto] w-full h-full  overflow-hidden p-2 text-2xs"
										>
											<div className="flex w-24 h-24 overflow-hidden m-auto rounded-full border-8 border-gray-200 mt-3 items-center justify-center">
												{bateau.media && bateau.media?.length > 0 ? (
													<SafeImage
														className="shadow-md h-auto w-full"
														src={`https://punch-croisieres.com/assets/media/voilier/${bateau.media_data?.fichier}`}
														alt="Example Image"
													/>
												) : (
													<div></div>
												)}
											</div>
											<div
												className={`flex   p-2 m-auto font-bold text-center items-center gap-2`}
											>
												<div className="">{bateau.titre_fr}</div>
											</div>
											<div className="flex flex-row h-12 items-center justify-center gap-2">
												<div className=" shrink h-12 w-12 flex rounded-full bg-blue-500/50 p-1 border-2 border-blue-700/70 ">
													{bateau.gamme &&
													bateau.gamme.nom_fr.toLowerCase() === "catamaran" ? (
														<CatamaranIcon className="x-8 w-8" />
													) : (
														<MonocoqueIcon className="x-8 w-8" />
													)}
												</div>
												{bateau.is_location ? (
													<div className=" shrink h-12 w-12 flex rounded-full bg-green-500/50 p-2 border-2 border-green-700/70  my-2 fill-green-800">
														<RentIcon className="h-6 w-6" />
													</div>
												) : (
													<></>
												)}
												{bateau.is_annonce ? (
													<div className=" shrink h-12 w-12 flex rounded-full bg-green-500/50 p-2 border-2 border-green-700/70  my-2 fill-green-800">
														<AnnonceIcon className="h-6 w-6" />
													</div>
												) : (
													<></>
												)}
											</div>
											<div
												className={`${
													showDetail ? "flex" : "hidden"
												} w-full overflow-hidden`}
											>
												<BateauResume bateau={bateau} />
											</div>
											<div className="flex flex-row gap-2 w-full h-10">
												<button
													className="flex flex-row items-center justify-between w-full mt-2 rounded-full text-white bg-blue-400 p-2"
													onClick={() => {
														handleSelectBateau(bateau);
													}}
												>
													<div className="">Modifier</div>
													<div className="">
														<PencilIcon className="h-4 w-4" />
													</div>
												</button>
												<button
													className="flex flex-row items-center justify-between w-full mt-2 rounded-full text-white bg-rose-400 p-2"
													onClick={async () => {
														if (bateau.id) {
															deleteBateau(bateau.id).then((response) => {
																setBateaux(response.data);
															});
														}
													}}
												>
													<div className="">Supprimer</div>
													<div className="">
														<TrashIcon className="h-4 w-4" />
													</div>
												</button>
											</div>
											{/* <div className="flex w-full flex-col text-xs p-2 overflow-hidden">
											<div className="col-span-2 capitalize ">Extrait : </div>
											<div className="flex w-full col-span-2 h-40 overflow-hidden  whitespace-normal">
												<div
													className=" w-full p-2 truncate overflow-hidden [&>*]:truncate"
													dangerouslySetInnerHTML={{ __html: bateau.texte_fr }}
												/>
											</div>
										</div> */}
										</div>
									);
								})
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default BateauList;
