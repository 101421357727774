import React, { useEffect, useState } from "react";
import moment from "moment";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";

import { useAppSettingStore } from "src/services/useAppSettingStore";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as enums from "src/lib/enums";
import UserList from "./UserList";
import UserForm from "./UserForm";
import { User } from "src/api/users/userTypes";
import { fetchUsers } from "src/api/users/userService";
import UserMenu from "./UserMenu";

function UserHome() {
	const navigate = useNavigate();
	let { id } = useParams();

	const [currentPage] = useAppSettingStore((state) => [state.currentPage]);

	let location = useLocation();

	const [userx, setUsers] = useState<User[]>([]);

	// const [selectedUser, setSelectedUser] = useState<User | undefined>(
	// 	userx.find((art) => id && art.id?.toString() === id)
	// );

	const selectedUser = userx.find((art) => id && art.id?.toString() === id);
	const [loading, setLoading] = useState(true);
	const handleSelectUser = (user?: User) => {
		if (user) {
			navigate(`${enums.PAGES_USER_EDIT}/${user.id}`);
		} else {
			navigate(enums.PAGES_USER_ADD);
		}
		//setSelectedUser(user);
	};
	useEffect(() => {
		const loadUserx = async () => {
			try {
				const data = (await fetchUsers()) as User[];

				setUsers(data);
			} catch (error: any) {
				debugger;
				if (error.response.data === "Failed to authenticate token.") {
					navigate("/login");
				}

				console.error("Failed to fetch userx", error);
			} finally {
				setLoading(false);
			}
		};

		loadUserx();
	}, []);

	useEffect(() => {
		//setSelectedUser(undefined);
	}, [location.pathname]);
	if (loading) return <p>Loading...</p>;

	return (
		<div className="flex flex-col w-full  h-full overflow-hidden">
			<UserMenu handleSelectUser={handleSelectUser} />
			{location.pathname.includes(enums.PAGES_USER_LIST) ? (
				<>
					<UserList handleSelectUser={handleSelectUser} />
				</>
			) : location.pathname.includes(enums.PAGES_USER_EDIT) ||
			  location.pathname.includes(enums.PAGES_USER_ADD) ? (
				<UserForm selectedUser={selectedUser} />
			) : (
				""
			)}
		</div>
	);
}

export default UserHome;
