import React, { useEffect, useState } from "react";
import * as contactService from "../../api/contacts/contactService";
import { Contact } from "src/api/contacts/contactTypes";
import {
	ChatBubbleOvalLeftEllipsisIcon,
	CheckBadgeIcon
} from "@heroicons/react/24/solid";
import MessageContent from "./MessageContent";
import moment from "moment";
function ContactList() {
	const [contacts, setContacts] = useState<Contact[]>([]);

	useEffect(() => {
		fetchContacts();
	}, []);

	const fetchContacts = async () => {
		try {
			const response = await contactService.fetchContacts();

			setContacts(response);
		} catch (error) {
			console.error("Erreur lors du chargement des contacts", error);
		}
	};

	const handleDelete = async (id: number) => {
		try {
			await contactService.deleteContact(id);
			fetchContacts(); // Refresh the contact list
		} catch (error) {
			console.error("Erreur lors de la suppression du contact", error);
		}
	};
	const groups: string[] = [];
	const groupMessagesByDate = (messages: Contact[]) => {
		const grouped = messages.reduce((groups: any, message) => {
			const date = parseInt(
				new Date(message.created_at)
					.toISOString()
					.split("T")[0]
					.replace(/-/g, "")
			); // Extract the date (YYYYMMDD)

			if (!groups[date]) {
				groups[date] = [];
			}

			groups[date].push(message);
			return groups;
		}, {});

		// Sort the keys (dates) in descending order
		const sortedKeys = Object.keys(grouped).sort((a, b) => b.localeCompare(a));

		// Create the final object with separate keys and values arrays
		return {
			keys: sortedKeys,
			values: sortedKeys.map((date) => grouped[date])
		};
	};

	const groupedMessages = groupMessagesByDate(contacts);
	let messageDate = "";
	return (
		<div className="grid grid-rows-[1fr] w-full h-full overflow-hidden">
			<div className="flex flex-col h-full w-full overflow-y-auto">
				{groupedMessages.keys.map((date, index) => {
					return (
						<div
							className="flex flex-col"
							key={date}
						>
							<ol className="relative border-s border-gray-200 dark:border-gray-700"></ol>
							<div className="flex  h-full  gap-4  font-[arial] mx-auto">
								<ol className="relative border-s border-gray-200 dark:border-gray-700">
									{groupedMessages.values[index].map((message: Contact) => {
										const newDate = (
											<li className="ms-4 mb-8">
												<div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
												<time className="flex w-full p-3 text-md font-bold leading-none bg-gray-100 rounded-md text-gray-700 dark:text-gray-500 capitalize">
													{moment(date, "YYYYMMDD").format("dddd D MMMM YYYY")}
												</time>
											</li>
										);
										let dateToShow = <></>;
										if (date !== messageDate) {
											messageDate = date;
											dateToShow = newDate;
										}
										return (
											<React.Fragment key={message.id}>
												{dateToShow}
												<MessageContent
													contact={message}
													handleDelete={handleDelete}
												/>
											</React.Fragment>
										);
									})}
								</ol>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
}

export default ContactList;
