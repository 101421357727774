import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
//import "react-quill/dist/quill.snow.css";
// import Editor, { ContentEditableEvent } from "react-simple-wysiwyg";

import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";
// import { PlainTextPlugin } from "@lexical/react/LexicalPlainTextPlugin";
//
// import { HeadingNode, QuoteNode } from "@lexical/rich-text";
// import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
// import { ListItemNode, ListNode } from "@lexical/list";
// import { CodeHighlightNode, CodeNode } from "@lexical/code";
// import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS } from "@lexical/markdown";

// import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevelPlugin";
// import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin";
// import AutoLinkPlugin from "./plugins/AutoLinkPlugin";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";

//

//import TreeViewPlugin from "../WYSIWYG/glyf-editor/TreeViewPlugin";

import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";

import SafeImage from "../images/SafeImage";
import WysiwygTextarea from "../WYSIWYG/WysiwygTextarea";
import { StarIcon, TrashIcon } from "@heroicons/react/24/solid";
import { StarIcon as OutStarIcon } from "@heroicons/react/24/outline";
import { checkImageExists } from "src/lib/functions";

import * as saisonService from "../../api/saisons/saisonService";
import ImageForm from "../media/ImageForm";
import { PAGES_ARTICLE_EDIT, PAGES_SAISON_EDIT } from "src/lib/enums";
import {
	BtnBold,
	BtnBulletList,
	BtnClearFormatting,
	BtnItalic,
	BtnLink,
	BtnNumberedList,
	BtnRedo,
	BtnStrikeThrough,
	BtnStyles,
	BtnUnderline,
	BtnUndo,
	ContentEditableEvent,
	Editor,
	EditorProvider,
	HtmlButton,
	Separator,
	Toolbar
} from "react-simple-wysiwyg";
import { Saison } from "src/api/saisons/saisonTypes";

const SaisonForm = ({ selectedSaison }: { selectedSaison?: Saison }) => {
	let location = useLocation();
	const navigate = useNavigate();
	let { id } = useParams();

	const [saison, setSaison] = useState<Saison>(selectedSaison ?? new Saison());
	const [saisonSended, setSaisonSended] = useState<Saison | undefined>();

	const [error, setError] = useState<string | null>(null);
	const [success, setSuccess] = useState<boolean>(false);

	const theme = {
		ltr: "ltr",
		rtl: "rtl",
		paragraph: "editor-paragraph",
		quote: "editor-quote",
		heading: {
			h1: "editor-heading-h1",
			h2: "editor-heading-h2",
			h3: "editor-heading-h3",
			h4: "editor-heading-h4",
			h5: "editor-heading-h5",
			h6: "editor-heading-h6"
		},
		list: {
			nested: {
				listitem: "editor-nested-listitem"
			},
			ol: "editor-list-ol",
			ul: "editor-list-ul",
			listitem: "editor-listItem",
			listitemChecked: "editor-listItemChecked",
			listitemUnchecked: "editor-listItemUnchecked"
		},
		hashtag: "editor-hashtag",
		image: "editor-image",
		link: "editor-link",
		text: {
			bold: "editor-textBold",
			code: "editor-textCode",
			italic: "editor-textItalic",
			strikethrough: "editor-textStrikethrough",
			subscript: "editor-textSubscript",
			superscript: "editor-textSuperscript",
			underline: "editor-textUnderline",
			underlineStrikethrough: "editor-textUnderlineStrikethrough"
		},
		code: "editor-code",
		codeHighlight: {
			atrule: "editor-tokenAttr",
			attr: "editor-tokenAttr",
			boolean: "editor-tokenProperty",
			builtin: "editor-tokenSelector",
			cdata: "editor-tokenComment",
			char: "editor-tokenSelector",
			class: "editor-tokenFunction",
			"class-name": "editor-tokenFunction",
			comment: "editor-tokenComment",
			constant: "editor-tokenProperty",
			deleted: "editor-tokenProperty",
			doctype: "editor-tokenComment",
			entity: "editor-tokenOperator",
			function: "editor-tokenFunction",
			important: "editor-tokenVariable",
			inserted: "editor-tokenSelector",
			keyword: "editor-tokenAttr",
			namespace: "editor-tokenVariable",
			number: "editor-tokenProperty",
			operator: "editor-tokenOperator",
			prolog: "editor-tokenComment",
			property: "editor-tokenProperty",
			punctuation: "editor-tokenPunctuation",
			regex: "editor-tokenVariable",
			selector: "editor-tokenSelector",
			string: "editor-tokenSelector",
			symbol: "editor-tokenProperty",
			tag: "editor-tokenProperty",
			url: "editor-tokenOperator",
			variable: "editor-tokenVariable"
		}
		// Theme styling goes here
		//...
	};
	const validateForm = (): string | null => {
		if (!saison.date_debut.trim()) return "La date de début est requise.";
		if (!saison.date_fin.trim()) return "La date de fin est requise.";
		if (!saison.nom.trim()) return "Le nom est requis.";

		// Ajoutez d'autres vérifications si nécessaire
		return null; // Retourne null si tout est valide
	};
	function onError(error: any) {
		console.error(error);
	}
	const initialConfig = {
		text: "initial text",
		namespace: "MyEditor",
		theme,
		onError
	};

	const handleChange = (
		e:
			| React.ChangeEvent<
					HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
			  >
			| ContentEditableEvent
	) => {
		const { name, value } = e.target;
		if (name) {
			setSaison({
				...saison,
				[name]: value
			});
		}
	};
	const handleQuillChange = (value: string, name: string) => {
		if (name) {
			setSaison({
				...saison,
				[name]: value
			});
		}
	};
	// const handleQuillChange = (e: ContentEditableEvent) => {
	// 	const { name, value } = e.target;
	// 	if (name) {
	// 		setSaison({
	// 			...saison,
	// 			[name]: value
	// 		});
	// 	}
	// };

	// const handleQuillChange = (value: string, name: string) => {
	// 	debugger;
	// 	setSaison({
	// 		...saison,
	// 		[name]: value
	// 	});
	// };
	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		let response = undefined;
		const validationError = validateForm();
		if (validationError) {
			setSuccess(false);
			setError(validationError);
			return;
		}
		if (saisonSended === saison) {
			setSuccess(false);
			setError("Aucune modification n'a été apportée.");
			return;
		}
		try {
			if (saison.id) {
				response = await saisonService.updateSaison(saison.id, saison);
			} else {
				response = await saisonService.createSaison({
					...saison
					// slug:
					//saison.titre_fr.toLowerCase().replace(/[^a-zA-Z0-9]/g, "") ??
					// 	Date.now().toString(36) + Math.random().toString(36).substr(2, 9)
				});
			}

			// const response = await axios.post("/saisons", saison);

			if (response.status === 200) {
				if (response.data.id) {
					navigate(`${PAGES_SAISON_EDIT}/${response.data.id}`);
				}
				setSuccess(true);
				setError(null);
				setSaison(response.data);
				setSaisonSended(response.data);
			} else {
				setSuccess(false);
				setError("Une erreur s'est produite.");
			}
		} catch (err) {
			setSuccess(false);
			setError("Une erreur s'est produite.");
		}
	};
	const [files, setFiles] = useState<FileList | null>(null);
	const [preview, setPreview] = useState<string | null>(null);
	const [dragActive, setDragActive] = useState(false);

	const isFormValide = validateForm() === null;
	useEffect(() => {
		if (files && files.length > 0) {
			const file = files[0];
			const reader = new FileReader();

			reader.onloadend = () => {
				setPreview(reader.result as string);
			};

			reader.readAsDataURL(file);
		} else {
			setPreview(null);
		}
	}, [files]);

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setFiles(event.target.files);
	};

	return (
		<div className="grid grid-rows-[auto_1fr] h-full overflow-hidden  mt-5 gap-2">
			<div className=" p-2">
				{error && (
					<div className="bg-red-100 border border-red-400 text-red-700 px-4 py-2 rounded relative mb-2">
						{error}
					</div>
				)}
				{success && (
					<div className="bg-green-100 border border-green-400 text-green-700 px-4 py-2 rounded relative mb-2">
						{id ? "Saison modifié avec succès!" : "Saison ajouté avec succès!"}
					</div>
				)}
			</div>

			<div className="grid w-full h-full grid-cols-[1fr_300px] gap-4 overflow-y-auto overflow-x-hidden px-10">
				<div className="grow ">
					<div className="col-span-2 space-y-4">
						<div className="bg-gray-100 p-4 rounded-md">
							<label className="block text-sm font-medium text-gray-700">
								Date début
							</label>
							<input
								type="date"
								name="date_debut"
								value={saison.date_debut || ""}
								onChange={handleChange}
								required
								className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
							/>
						</div>
						<div className="bg-gray-100 p-4 rounded-md">
							<label className="block text-sm font-medium text-gray-700">
								Date fin
							</label>
							<input
								type="date"
								name="date_fin"
								value={saison.date_fin || ""}
								onChange={handleChange}
								required
								className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
							/>
						</div>

						<div className="bg-gray-100 p-4 rounded-md">
							<label className="block text-sm font-medium text-gray-700">
								Nom
							</label>
							<input
								type="text"
								name="nom"
								value={saison.nom || ""}
								onChange={handleChange}
								className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
							/>
						</div>
					</div>
				</div>
			</div>
			<div className=" p-2">
				<button
					disabled={
						(saison === selectedSaison && saisonSended !== saison) ||
						!isFormValide
					}
					onClick={handleSubmit}
					className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 disabled:bg-gray-400 p-2"
				>
					{id ? "Modifier" : "Ajouter"}
				</button>
			</div>
		</div>
	);
};

export default SaisonForm;
