import * as types from "src/lib/types";
import * as enums from "src/lib/enums";
import * as menuTypes from "src/lib/menu-types";
import { create } from "zustand";

interface AppStore {
	isOpen: boolean;
	bgColor: string;
	bgColor2: string;
	currentPage: string;
	currentSubPage: string;
	setCurrentPage: (currentPage: string) => void;
	setCurrentSubPage: (currentSubPage: string) => void;
	setBgColors: (bgColor: string, bgColor2: string) => void;
	openMenu: () => void;
	closeMenu: () => void;
	isDrawerOpen: boolean;
	drawerContent?: React.ReactElement;
	drawerPlacement?: types.Side;
	drawerWidth?: string;
	openDrawer: (drawerWidth?: string) => void;
	closeDrawer: () => void;
	setDrawerContent: (
		drawerContent: React.ReactElement,
		drawerPlacement?: types.Side
	) => void;
	roomOptions: types.RoomOption;
	setRoomOptions: (roomOptions?: types.RoomOption) => void;
	selectedTile?: number;
	setSelectedTile: (selectedTile: number) => void;
	selectedSection?: types.Section;
	setSelectedSection: (selectedSection: types.Section) => void;
	appLanguage: menuTypes.Language;
	setAppLanguage: (appLanguageDetail: menuTypes.LanguageDetail) => void;
	menuLanguage: menuTypes.Language;
	setMenuLanguage: (menuLanguageDetail: menuTypes.LanguageDetail) => void;
	testMenuLanguage: menuTypes.Language;
	setTestMenuLanguage: (
		testMenuLanguageDetail: menuTypes.LanguageDetail
	) => void;

	appConfig: types.AppConfig;
	dashboardSetting: types.DashboardSetting;
	setDashboardSetting: (dashboardSetting: types.DashboardSetting) => void;
	appTheme: types.AppTheme;
	setAppTheme: (appTheme: types.AppTheme) => void;
	isMobile: boolean;
	setIsMobile: (isMobile: boolean) => void;
	headerContent?: React.ReactNode | string;
	setHeaderContent: (headerContent: React.ReactNode | string) => void;
	isLeftOver: boolean;
	setIsLeftOver: (isLeftOver: boolean) => void;
	isLeftOpen: boolean;
	setIsLeftOpen: (isLeftOpen: boolean) => void;
}

export const useAppSettingStore = create<AppStore>()((set, get) => ({
	appConfig: new types.AppConfig(),
	bgColor: "[#FDFBF9]",
	bgColor2: "blue-400",

	currentPage: enums.PAGES_DASHBOARD,
	currentSubPage: enums.PAGES_DASHBOARD,
	setCurrentPage: (currentPage: string) => {
		set({ currentPage });
	},
	setCurrentSubPage: (currentSubPage: string) => {
		set({ currentSubPage });
	},
	setBgColors: (bgColor: string, bgColor2: string) => {
		set({ bgColor, bgColor2 });
	},
	isOpen: false,
	isDrawerOpen: false,
	roomOptions: new types.RoomOption(),

	openMenu: () => set({ isOpen: true }),
	closeMenu: () => set({ isOpen: false }),
	openDrawer: (drawerWidth?: string) =>
		set({ isDrawerOpen: true, drawerWidth }),
	closeDrawer: () =>
		set({
			drawerPlacement: "right",
			isDrawerOpen: false,
			drawerContent: undefined,
			drawerWidth: undefined
		}),
	setDrawerContent: (
		drawerContent: React.ReactElement,
		drawerPlacement?: types.Side
	) => set({ drawerContent, drawerPlacement }),
	setRoomOptions: (roomOptions?: types.RoomOption) => set({ roomOptions }),
	setSelectedTile: (selectedTile: number) => set({ selectedTile }),
	setSelectedSection: (selectedSection: types.Section) =>
		set({ selectedSection }),
	appLanguage: "fr",
	setAppLanguage: (appLanguageDetail: menuTypes.LanguageDetail) =>
		set({ appLanguage: appLanguageDetail.id }),
	menuLanguage: "fr",
	setMenuLanguage: (menuLanguageDetail: menuTypes.LanguageDetail) =>
		set({ menuLanguage: menuLanguageDetail.id }),
	testMenuLanguage: "fr",
	setTestMenuLanguage: (testMenuLanguageDetail: menuTypes.LanguageDetail) =>
		set({ testMenuLanguage: testMenuLanguageDetail.id }),
	dashboardSetting: new types.DashboardSetting(),
	setDashboardSetting: (dashboardSetting: types.DashboardSetting) =>
		set({ dashboardSetting }),
	appTheme: new types.AppTheme(),
	setAppTheme: (appTheme: types.AppTheme) => set({ appTheme }),
	isMobile: false,
	setIsMobile: (isMobile: boolean) => set({ isMobile }),

	setHeaderContent: (headerContent: React.ReactNode | string) =>
		set({ headerContent }),
	isLeftOver: false,
	setIsLeftOver: (isLeftOver: boolean) => set({ isLeftOver }),
	isLeftOpen: false,
	setIsLeftOpen: (isLeftOpen: boolean) => set({ isLeftOpen })
}));
