import React from "react";

const RentIcon = ({ className }: { className?: string }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			className={`${className}`}
			viewBox="0 0 325.869 325.869"
		>
			<g
				id="Groupe_7"
				data-name="Groupe 7"
				transform="translate(16918.91 -152.386)"
			>
				<path
					id="Tracé_13"
					data-name="Tracé 13"
					d="M-16918.91,189.5h14.846l78.684-33.4a31.859,31.859,0,0,1,14.1-3.712c7.609,0,16.332,3.712,16.332,3.712l45.279,21.527a28.052,28.052,0,0,1,10.393,7.423c4.082,5.011,5.938,12.619,5.938,12.619l25.98,42.311s9.65,13.361,0,19.3-19.3-5.2-19.3-5.2l-37.857-43.8-37.115-12.619s-19.311-7.423-20.783,12.619,20.783,19.3,20.783,19.3h19.3s18.223,2.83,30.469,7.469,18.523,11.088,18.523,11.088,9.465,12.967,6.682,20.019-17.814,8.189-17.814,8.189-19.488-6.681-46.766-6.681-36,10.578-62.354,6.681-26.723-15.217-43.053-22.269-22.27-5.938-22.27-5.938Z"
					fill="inherit"
				/>
				<path
					id="Tracé_14"
					data-name="Tracé 14"
					d="M-16713.074,324.072l28.947,3.711s1.484,5.2,5.2,5.938,7.238.557,9.648-2.969,0-11.134,0-11.134l6.68-6.681s5.939,2.041,10.395,0,7.422-8.165,7.422-8.165,11.135,10.392,7.422,28.95-11.875,22.269-11.875,22.269h-13.363v51.961l-6.68,8.908h-14.846l-6.68-8.908V355.991h-12.619s-7.238-6.866-9.648-14.846S-16713.074,324.072-16713.074,324.072Z"
					transform="translate(-55.148 -41.041)"
					fill="inherit"
				/>
				<path
					id="Tracé_15"
					data-name="Tracé 15"
					d="M-16771.51,481.86l-30.434-11.877s-9.65-5.938-16.33,0-3.711,11.877-3.711,11.877l-13.363-3.711s-8.906-3.712-14.1,8.908,2.969,17.073,2.969,17.073l126.189,51.961s6.865,5.2,14.1,5.2,14.848-5.2,14.848-5.2l38.6-13.361s14.66-7.423,28.949-7.423,28.211,7.423,28.211,7.423l20.783-60.868-40.826-16.331a58.123,58.123,0,0,0-30.434-4.454c-17.633,2.041-40.086,12.619-40.086,12.619s-19.113,6.124-34.146,8.165a90.027,90.027,0,0,1-25.98,0s-17.072,2.227-16.33,15.588,16.33,16.331,16.33,16.331,31.549,2.413,54.932,0,38.6-9.65,38.6-9.65.93,12.062-19.3,17.815-38.973,5.2-61.613,5.2-28.949-5.2-28.949-5.2-11.879-7.794-14.1-17.815S-16771.51,481.86-16771.51,481.86Z"
					transform="translate(-18.241 -83.031)"
					fill="inherit"
				/>
			</g>
		</svg>
	);
};

export default RentIcon;
