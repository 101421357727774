import React, { useEffect, useState } from "react";

import moment from "moment";
import SaisonMenu from "./SaisonMenu";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import SafeImage from "../images/SafeImage";
import { checkImageExists } from "src/lib/functions";
import { Saison } from "src/api/saisons/saisonTypes";
import * as saisonService from "src/api/saisons/saisonService";

const SaisonList = ({
	handleSelectSaison
}: {
	handleSelectSaison: (saison?: Saison) => void;
}) => {
	const navigate = useNavigate();

	const [saisons, setSaisons] = useState<Saison[]>([]);
	const [loading, setLoading] = useState(true);

	const loadSaisons = async () => {
		try {
			const data = (await saisonService.fetchSaisons()) as Saison[];
			setSaisons(data);
		} catch (error: any) {
			debugger;
			if (error.response.data === "Failed to authenticate token.") {
				navigate("/login");
			}

			console.error("Failed to fetch saisons", error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		loadSaisons();
	}, []);

	if (loading) return <p>Loading...</p>;

	return (
		<>
			<div className="flex flex-col w-full text-sm max-w-5xl h-full m-auto overflow-y-auto pb-10 mt-2 gap-3">
				<div
					className={`relative grid grid-cols-[auto_auto_1fr_auto_auto] shadow-md rounded-md items-center bg-gray-300`}
				>
					<div className="flex w-[150px] h-full px-2 gap-2 items-center">
						Date début
					</div>
					<div className="flex w-[150px] h-full px-2 gap-2 items-center">
						Date fin
					</div>
					<div className="flex h-full px-2 gap-2 items-center">Nom</div>
					<div className="flex w-full gap-2 p-2"></div>
				</div>
				{saisons.map((saison) => {
					return (
						<React.Fragment key={saison.id}>
							<div
								className={`relative grid grid-cols-[auto_auto_1fr_auto_auto] shadow-md rounded-md items-center odd:bg-gray-200 even:bg-gray-50`}
							>
								<div className="flex w-[150px] h-full px-2 gap-2 items-center">
									{moment(saison.date_debut, "YYYY-MM-DD").format(
										"D MMMM YYYY"
									)}
								</div>
								<div className="flex w-[150px] h-full px-2 gap-2 items-center">
									{moment(saison.date_fin, "YYYY-MM-DD").format("D MMMM YYYY")}
								</div>
								<div className="flex h-full px-2 gap-2 items-center">
									{saison.nom}
								</div>
								<div className="flex w-full gap-2 p-2">
									<button
										className="grid grid-cols-[auto_1fr] rounded-md bg-indigo-600 px-3 py-2 mx-auto text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 whitespace-nowrap items-center justify-between w-full"
										onClick={() => {
											handleSelectSaison(saison);
										}}
									>
										<div className="">
											<PencilIcon className="w-4 h-4" />
										</div>
										<div className="">Modifier</div>
									</button>
									<button
										className="grid grid-cols-[auto_1fr] rounded-md bg-rose-600 px-3 py-2  mx-auto text-sm font-semibold text-white shadow-sm hover:bg-rose-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-rose-600 whitespace-nowrap items-center justify-between w-full"
										onClick={() => {
											if (saison.id) {
												saisonService.deleteSaison(saison.id);
												loadSaisons();
											}
										}}
									>
										<div className="">
											<TrashIcon className="w-4 h-4" />
										</div>
										<div className="">Supprimer</div>
									</button>
								</div>
							</div>
						</React.Fragment>
					);
				})}
			</div>
		</>
	);
};

export default SaisonList;
