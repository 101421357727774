import { TrashIcon } from "@heroicons/react/24/solid";
import moment from "moment";
import React from "react";
import { deleteMedia } from "src/api/medias/mediaService";
import { Media } from "src/api/medias/mediaTypes";

const MediaResume = ({ media }: { media: Media }) => {
	return (
		<div className="relative grid grid-cols-[45px_80px] w-full gap-2 text-xs text-left leading-4 ">
			<div className="capitalize whitespace-nowrap">date : </div>
			<div className="whitespace-nowrap">
				{moment(media.created_at).format("DD/MM/YYYY")}
			</div>
			<div className="capitalize whitespace-nowrap">titre : </div>
			<div className="flex flex-col has-tooltip">
				<div className="tooltip rounded shadow-lg p-2 bg-gray-100 -mt-8 whitespace-nowrap">
					{media.titre}
				</div>
				<div className="truncate whitespace-nowrap">{media.titre}</div>
			</div>
			<div className="capitalize whitespace-nowrap">rép. : </div>
			<div className="truncate">{media.repertoire}</div>
			<div className="capitalize whitespace-nowrap">type : </div>
			<div className="whitespace-nowrap">{media.type}</div>
			<div className="col-span-2">
				<button
					className="flex flex-row items-center justify-between w-full mt-2 rounded-md text-white bg-rose-500 p-2"
					onClick={() => {
						if (media.id) {
							deleteMedia(media.id.toString());
						}
					}}
				>
					<div className="">Supprimer</div>
					<div className="">
						<TrashIcon className="h-6 w-6" />
					</div>
				</button>
			</div>
		</div>
	);
};

export default MediaResume;
