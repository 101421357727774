import React, { useEffect, useState } from "react";
import {
	deleteMedia,
	fetchMedias,
	mediaFileUpload
} from "../../api/medias/mediaService";
import { Media } from "../../api/medias/mediaTypes";
import { checkImageExists } from "src/lib/functions";
import SafeImage from "../images/SafeImage";
import moment from "moment";
import { DocumentIcon, TrashIcon } from "@heroicons/react/24/solid";

import * as articleService from "../../api/articles/articleService";
import AddMedia from "./AddMedia";
import MediaResume from "./MediaResume";

const MediaGallery: React.FC = () => {
	const [medias, setMedias] = useState<Media[]>([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<string | null>(null);
	const [imageRepertoire, setImageRepertoire] = useState<string | null>(null);
	const [imageType, setImageType] = useState<string | null>(null);
	const [imageFilter, setImageFilter] = useState<string | null>(null);

	useEffect(() => {
		const loadMedias = async () => {
			try {
				const data = await fetchMedias();
				setMedias(data);
			} catch (err) {
				setError("Failed to load media");
			} finally {
				setLoading(false);
			}
		};

		loadMedias();
	}, []);
	const [files, setFiles] = useState<FileList | null>(null);
	const [preview, setPreview] = useState<string | null>(null);
	const [dragActive, setDragActive] = useState(false);

	useEffect(() => {
		if (files && files.length > 0) {
			const file = files[0];
			const reader = new FileReader();

			reader.onloadend = () => {
				setPreview(reader.result as string);
			};

			reader.readAsDataURL(file);
		} else {
			setPreview(null);
		}
	}, [files]);

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setFiles(event.target.files);
	};

	const handleFormSubmit = async (event: React.FormEvent) => {
		event.preventDefault();

		if (!files || files.length === 0) {
			alert("Please select a file to upload.");
			return;
		}

		// const formData = new FormData();
		// for (const file of Array.from(files)) {
		// 	formData.append("files", file);
		// }

		try {
			const response = await mediaFileUpload(files[0]);

			if (response.ok) {
				alert("Files uploaded successfully!");
			} else {
				alert("Failed to upload files.");
			}
		} catch (error) {
			console.error("Error:", error);
			alert("An error occurred while uploading the files.");
		}
	};

	const handleDragOver = (event: React.DragEvent) => {
		event.preventDefault();
		setDragActive(true);
	};

	const handleDragLeave = (event: React.DragEvent) => {
		event.preventDefault();
		setDragActive(false);
	};

	const handleDrop = (event: React.DragEvent) => {
		event.preventDefault();
		setDragActive(false);
		setFiles(event.dataTransfer.files);
	};

	const handleDivClick = () => {
		const fileInput = document.getElementById("fileInput") as HTMLInputElement;
		fileInput.click();
	};
	if (loading) return <p>Loading...</p>;
	if (error) return <p>{error}</p>;

	return (
		<div className="flex flex-col h-full overflow-y-auto media-gallery">
			<h1 className="text-3xl font-bold mb-5">Media Gallery</h1>
			<div className="flex flex-row gap-2 max-h-52">
				<div className="bg-gray-100 p-4 rounded-md">
					<h2 className="text-lg font-medium text-gray-700 mb-2">
						Ajouter des fichiers
					</h2>
					<form
						onSubmit={handleFormSubmit}
						encType="multipart/form-data"
					>
						<input
							type="file"
							id="fileInput"
							name="files"
							multiple
							onChange={handleFileChange}
							style={{ display: "none" }} // Hide the default file input
						/>
						<div
							className={`border-dashed border-2 rounded-md p-6 text-center ${
								dragActive ? "border-blue-500" : "border-gray-300"
							}`}
							onClick={handleDivClick}
							onDragOver={handleDragOver}
							onDragLeave={handleDragLeave}
							onDrop={handleDrop}
						>
							<p className="text-gray-600">
								Sélectionner des fichiers ou déposer ici
							</p>
						</div>

						<button
							className="w-full mt-2 rounded-md text-white bg-blue-500 p-2"
							type="submit"
						>
							Upload
						</button>
					</form>
				</div>
				{preview && (
					<div className="bg-gray-100 p-4 rounded-md">
						<div className="">
							<img
								src={preview}
								alt="Preview"
								className="max-w-full max-h-40 h-auto rounded-md"
							/>
						</div>
					</div>
				)}
			</div>

			<div className="flex flex-row gap-2">
				<div className="flex  flex-row gap-2 items-center my-2">
					<div className="font-bold whitespace-nowrap">Répertoire :</div>
					<div className="flex w-full flex-row gap-2  items-center">
						<button
							className={`p-1 rounded-md  ${
								imageRepertoire ? "bg-gray-100" : "bg-blue-400 text-white"
							}`}
							onClick={() => {
								setImageRepertoire(null);
							}}
						>
							Tout
						</button>
						<button
							className={`p-1 rounded-md  ${
								imageRepertoire === "article"
									? "bg-blue-400 text-white"
									: "bg-gray-100"
							}`}
							onClick={() => {
								setImageRepertoire("article");
							}}
						>
							article
						</button>
						<button
							className={`p-1 rounded-md  ${
								imageRepertoire === "voilier"
									? "bg-blue-400 text-white"
									: "bg-gray-100"
							}`}
							onClick={() => {
								setImageRepertoire("voilier");
							}}
						>
							voilier
						</button>
						<div className=""></div>
					</div>
				</div>
				<div className="flex  flex-row gap-2 items-center my-2">
					<div className="font-bold whitespace-nowrap">Type :</div>
					<div className="flex w-full flex-row gap-2  items-center">
						<button
							className={`p-1 rounded-md  ${
								imageType ? "bg-gray-100" : "bg-blue-400 text-white"
							}`}
							onClick={() => {
								setImageType(null);
							}}
						>
							Tout
						</button>
						<button
							className={`p-1 rounded-md  ${
								imageType === "image" ? "bg-blue-400 text-white" : "bg-gray-100"
							}`}
							onClick={() => {
								setImageType("image");
							}}
						>
							image
						</button>
						<button
							className={`p-1 rounded-md  ${
								imageType === "document"
									? "bg-blue-400 text-white"
									: "bg-gray-100"
							}`}
							onClick={() => {
								setImageType("document");
							}}
						>
							document
						</button>
						<div className=""></div>
					</div>
				</div>
			</div>

			<div className="flex flex-wrap   media-grid">
				{medias.length === 0 ? (
					<p>No media available</p>
				) : (
					medias
						.sort(
							(a, b) =>
								parseInt(moment(b.created_at).format("YYYYMMDD")) -
								parseInt(moment(a.created_at).format("YYYYMMDD"))
						)
						.filter(
							(img) =>
								//((imageFilter && img.type === imageFilter) || !imageFilter) &&
								((imageRepertoire && img.repertoire === imageRepertoire) ||
									!imageRepertoire) &&
								((imageType && img.type === imageType) || !imageType)
						)
						.map((media) => {
							let imageFromArticle: false | Promise<boolean> = false;
							let imageFromEscale: false | Promise<boolean> = false;
							let imageFromVoiler: false | Promise<boolean> = false;
							if (media) {
								imageFromArticle = media.fichier
									? checkImageExists(
											`https://punch-croisieres.com/assets/media/article/${media.fichier}`
									  )
									: false;
								imageFromEscale = media.fichier
									? checkImageExists(
											`https://punch-croisieres.com/assets/media/escale/${media.fichier}`
									  )
									: false;
								imageFromVoiler = media.fichier
									? checkImageExists(
											`https://punch-croisieres.com/assets/media/voilier/${media.fichier}`
									  )
									: false;
							}

							return (
								<div
									key={media.id}
									className="flex shadow-md rounded-md media-item bg-gray-50"
								>
									{media.type === "image" ? (
										<div className="grid grid-rows-[1fr_auto] h-full w-36 gap-2">
											<div className="flex shadow-md w-36 max-h-36 overflow-hidden">
												{imageFromArticle ? (
													<SafeImage
														src={`https://punch-croisieres.com/assets/media/article/${media.fichier}`}
														alt="Example Image"
													/>
												) : imageFromEscale ? (
													<SafeImage
														src={`https://punch-croisieres.com/assets/media/escale/${media.fichier}`}
														alt="Example Image"
													/>
												) : imageFromVoiler ? (
													<SafeImage
														src={`https://punch-croisieres.com/assets/media/voilier/${media.fichier}`}
														alt="Example Image"
													/>
												) : (
													<></>
												)}
											</div>
											<MediaResume media={media} />
										</div>
									) : media.type === "video" ? (
										<video
											controls
											className="media-video"
										>
											<source
												src={media.fichier}
												type="video/mp4"
											/>
											Your browser does not support the video tag.
										</video>
									) : media.type === "document" ? (
										<>
											<div className="">
												<div className="flex items-center justify-center">
													<DocumentIcon className="w-14 h-36" />
												</div>
												<div className="relative grid grid-cols-[45px_80px] w-full gap-2 text-xs text-left leading-4 ">
													<div className="capitalize whitespace-nowrap">
														date :{" "}
													</div>
													<div className="whitespace-nowrap">
														{moment(media.created_at).format("DD/MM/YYYY")}
													</div>
													<div className="capitalize whitespace-nowrap">
														titre :{" "}
													</div>
													<div className="flex flex-col has-tooltip">
														<div className="tooltip rounded shadow-lg p-2 bg-gray-100 -mt-8 whitespace-nowrap">
															{media.titre}
														</div>
														<div className="truncate whitespace-nowrap">
															{media.titre}
														</div>
													</div>
													<div className="capitalize whitespace-nowrap">
														rép. :{" "}
													</div>
													<div className="truncate">{media.repertoire}</div>
													<div className="capitalize whitespace-nowrap">
														type :{" "}
													</div>
													<div className="whitespace-nowrap">{media.type}</div>
													<div className="col-span-2">
														<button
															className="flex flex-row items-center justify-between w-full mt-2 rounded-md text-white bg-rose-500 p-2"
															onClick={() => {
																if (media.id) {
																	deleteMedia(media.id.toString());
																}
															}}
														>
															<div className="">Supprimer</div>
															<div className="">
																<TrashIcon className="h-6 w-6" />
															</div>
														</button>
													</div>
												</div>
											</div>
										</>
									) : (
										<>
											<p>Unsupported media type</p>
											<div className="relative grid grid-cols-[45px_80px] w-full gap-2 text-xs text-left leading-4 ">
												<div className="capitalize whitespace-nowrap">
													date :{" "}
												</div>
												<div className="whitespace-nowrap">
													{moment(media.created_at).format("DD/MM/YYYY")}
												</div>
												<div className="capitalize whitespace-nowrap">
													titre :{" "}
												</div>
												<div className="flex flex-col has-tooltip">
													<div className="tooltip rounded shadow-lg p-2 bg-gray-100 -mt-8 whitespace-nowrap">
														{media.titre}
													</div>
													<div className="truncate whitespace-nowrap">
														{media.titre}
													</div>
												</div>
												<div className="capitalize whitespace-nowrap">
													rép. :{" "}
												</div>
												<div className="truncate">{media.repertoire}</div>
												<div className="capitalize whitespace-nowrap">
													type :{" "}
												</div>
												<div className="whitespace-nowrap">{media.type}</div>
												<div className="col-span-2">
													<button
														className="flex flex-row items-center justify-between w-full mt-2 rounded-md text-white bg-rose-500 p-2"
														onClick={() => {
															if (media.id) {
																deleteMedia(media.id.toString());
															}
														}}
													>
														<div className="">Supprimer</div>
														<div className="">
															<TrashIcon className="h-6 w-6" />
														</div>
													</button>
												</div>
											</div>
										</>
									)}
								</div>
							);
						})
				)}
			</div>
		</div>
	);
};

export default MediaGallery;
