import axiosInstance from "../axiosConfig";
import { Parametre } from "./configTypes";

// Récupérer tous les paramètres de configuration
export const fetchParametres = async () => {
	const response = await axiosInstance.get("/parametres");
	return response.data;
};

// Récupérer un paramètre de configuration par clé
export const fetchParametreByKey = async (key: string) => {
	const response = await axiosInstance.get(`/parametres/${key}`);
	return response.data;
};

// Mettre à jour un paramètre de configuration
export const updateParametre = async (key: string, parametre: Parametre) => {
	const response = await axiosInstance.put(`/parametres/${key}`, parametre);
	return response.data;
};
