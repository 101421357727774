import { Media } from "src/api/medias/mediaTypes";

export class Escale {
	id?: number;

	lieu = "";
	description = "";
	gps = "";
	type_id = 0;
	type?: EscaleType;
}
export class EscaleType {
	id?: number;
	nom: string = "";
	icon: string = "";
}
