import React, { useEffect, useState } from "react";
import { AppBar, Toolbar, Typography, Button } from "@mui/material";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "src/context/AuthContext";
import * as enums from "src/lib/enums";

const NavBar: React.FC = () => {
	const { user, logout } = useAuth();
	const navigate = useNavigate();
	const location = useLocation();
	const params = useParams();

	const [pageTitle, setPageTitle] = useState("");

	const handleLogout = () => {
		logout();
		navigate("/login");
	};
	useEffect(() => {
		if (!user) {
			navigate("/login");
		}
	}, [user]);
	const titles = {
		[enums.PAGES_ARTICLE_LIST]: "Articles",
		[enums.PAGES_ARTICLE_EDIT]: "Modifier un article",
		[enums.PAGES_ARTICLE_ADD]: "Ajouter un article",
		[enums.PAGES_ESCALE_LIST]: "Escales",
		[enums.PAGES_ESCALE_EDIT]: "Modifier un escale",
		[enums.PAGES_ESCALE_ADD]: "Ajouter un escale",
		[enums.PAGES_MEDIA_LIST]: "Medias",
		[enums.PAGES_BOAT_LIST]: "Bateaux",
		[enums.PAGES_BOAT_ADD]: "Ajouter un bateau",
		[enums.PAGES_BOAT_EDIT]: "Modifier un bateau",
		[enums.PAGES_MESSAGE_LIST]: "Messages",
		[enums.PAGES_USER_LIST]: "Utilisateurs",
		[enums.PAGES_USER_ADD]: "Ajouter un utilisateur",
		[enums.PAGES_USER_EDIT]: "Modifier un utilisateur",
		"/login": "Login",
		"/configuration": "Configuration Panel",
		"/": "Tableau de Bord"
	};
	useEffect(() => {
		const currentTitle = Object.keys(titles).find((key) =>
			location.pathname.includes(key)
		);

		// Ensure the found key is of the correct type
		if (currentTitle && titles[currentTitle as keyof typeof titles]) {
			const title = titles[currentTitle as keyof typeof titles];
			setPageTitle(title);
			//document.title = title; // Update the browser's document title
		} else {
			setPageTitle("Default Title");
			//document.title = "Default Title"; // Fallback title if no match is found
		}
	}, [location.pathname]); // Re-run this effect when the pathname changes

	return (
		<AppBar position="static">
			<Toolbar>
				<Typography
					variant="h6"
					sx={{ flexGrow: 1 }}
				>
					{pageTitle}
				</Typography>
				<Button
					color="inherit"
					component={Link}
					to="/"
				>
					Dashboard
				</Button>
				<Button
					color="inherit"
					component={Link}
					to={enums.PAGES_ARTICLE_LIST}
				>
					Articles
				</Button>
				<Button
					color="inherit"
					component={Link}
					to={enums.PAGES_ESCALE_LIST}
				>
					Cartes
				</Button>
				<Button
					color="inherit"
					component={Link}
					to={enums.PAGES_MEDIA_LIST}
				>
					Médias
				</Button>
				<Button
					color="inherit"
					component={Link}
					to={enums.PAGES_BOAT_LIST}
				>
					Bateaux
				</Button>
				<Button
					color="inherit"
					component={Link}
					to={enums.PAGES_TARIF_LIST}
				>
					Grille de tarifs
				</Button>
				<Button
					color="inherit"
					component={Link}
					to={enums.PAGES_MESSAGE_LIST}
				>
					Messages
				</Button>
				{/* <Button
					color="inherit"
					component={Link}
					to={enums.PAGES_USER_LIST}
				>
					Users
				</Button>
				<Button
					color="inherit"
					component={Link}
					to="/configuration"
				>
					Configuration
				</Button> */}
				<Button
					className="text-white !bg-red-500 !hover:bg-red-700 p-2 rounded"
					color="inherit"
					onClick={handleLogout}
					//to="/configuration"
				>
					Déconnexion
				</Button>
			</Toolbar>
		</AppBar>
	);
};

export default NavBar;
