import { ListItemIcon } from "@mui/material";
import { ListBulletIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import React from "react";
import { useNavigate } from "react-router-dom";
import * as enums from "src/lib/enums";
import { Bateau } from "src/api/bateaux/bateauTypes";
function BateauMenu({
	handleSelectBateau
}: {
	handleSelectBateau: (bateau?: Bateau) => void;
}) {
	const navigate = useNavigate();
	return (
		<div className="flex flex-row gap-3 items-center p-2 bg-blue-200/20 shadow-[0_5px_10px_-5px_rgba(0,0,0,0.3)]">
			<div
				className="flex flex-row gap-2 items-center p-2 cursor-pointer"
				onClick={() => {
					navigate(enums.PAGES_BOAT_LIST);
				}}
			>
				<ListBulletIcon className="w-8 h-8" /> Liste des bateaux
			</div>
			<div
				className="flex flex-row gap-2 items-center p-2 cursor-pointer"
				onClick={() => {
					handleSelectBateau(undefined);
				}}
			>
				<PlusCircleIcon className="w-8 h-8" />
				Ajouter un bateau
			</div>
		</div>
	);
}

export default BateauMenu;
