import React, { useState } from "react";

interface SwitchButtonProps {
	initialValue?: boolean;
	onToggle?: (value: boolean, inputName?: string) => void;
	name?: string;
}

const SwitchButton: React.FC<SwitchButtonProps> = ({
	initialValue = false,
	onToggle,
	name
}) => {
	const [isOn, setIsOn] = useState(initialValue);

	const handleToggle = () => {
		const newValue = !isOn;
		setIsOn(newValue);
		if (onToggle) {
			onToggle(newValue, name);
		}
	};

	return (
		<div
			onClick={handleToggle}
			className={`inline-flex items-center cursor-pointer w-20 p-1 rounded-full text-2xs select-none ${
				isOn ? "bg-green-500" : "bg-gray-300"
			}`}
		>
			<div
				className={`flex transform bg-white w-4 h-4 rounded-full shadow-md transition-transform duration-300 ${
					isOn ? "translate-x-14" : "translate-x-0"
				}`}
			/>
			<span
				className={`ml-3 font-medium transition-transform duration-300  ${
					isOn ? "-translate-x-4" : "translate-x-0"
				}`}
			>
				{isOn ? "Oui" : "Non"}
			</span>
		</div>
	);
};

export default SwitchButton;
