import React, { useEffect, useState } from "react";
import { fetchArticles } from "../../api/articles/articleService";
import { Article } from "../../api/articles/articleTypes";
import moment from "moment";
import ArticleMenu from "./ArticleMenu";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import SafeImage from "../images/SafeImage";
import { checkImageExists } from "src/lib/functions";

const ArticleList = ({
	handleSelectArticle
}: {
	handleSelectArticle: (article?: Article) => void;
}) => {
	const navigate = useNavigate();
	const [articles, setArticles] = useState<Article[]>([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const loadArticles = async () => {
			try {
				const data = (await fetchArticles()) as Article[];

				setArticles(
					data.sort((a, b) => {
						return (
							parseInt(moment(b.created_at).format("YYYYMMDDHHmm")) -
							parseInt(moment(a.created_at).format("YYYYMMDDHHmm"))
						);
					})
				);
			} catch (error: any) {
				debugger;
				if (error.response.data === "Failed to authenticate token.") {
					navigate("/login");
				}

				console.error("Failed to fetch articles", error);
			} finally {
				setLoading(false);
			}
		};

		loadArticles();
	}, []);
	const categ = ["Article", "Actualité", "Page"];
	if (loading) return <p>Loading...</p>;

	return (
		<>
			<div className="flex flex-wrap w-full text-sm max-w-5xl h-full m-auto overflow-y-auto pb-10 mt-2 gap-3">
				{articles.map((article) => {
					return (
						<React.Fragment key={article.id}>
							<div
								className={`relative grid grid-rows-[auto_auto_auto_1fr_auto] shadow-md rounded-md items-center odd:bg-gray-200 even:bg-gray-50 max-w-[250px] min-w-[200px]`}
							>
								<div className="flex w-full absolute top-0 p-3 font-bold shadow-b-md bg-white/80 items-center justify-center rounded-t-md">
									{categ[article.categorie_id]}
								</div>
								<div className="min-h-[50px] h-full p-2">
									{article.media && article.media?.length > 0 ? (
										/* {(article.media ?? []).map((med, med_index) => {
								return (
									<React.Fragment key={med_index}> */
										<div className="flex w-full overflow-hidden items-center justify-center">
											<SafeImage
												src={`https://punch-croisieres.com/assets/media/article/${article.media_data?.fichier}`}
												alt="Example Image"
											/>
										</div>
									) : (
										/* </React.Fragment>
								);
							})} */
										""
									)}
								</div>
								<div className="flex h-full px-2 gap-2">
									<span className="whitespace-nowrap font-bold capitalize">
										date :
									</span>
									<span className="">
										{moment(article.created_at).format("DD/MM/YYYY")}
									</span>
								</div>
								<div className="flex h-full px-2 gap-2">
									<span className="whitespace-nowrap font-bold capitalize">
										titre :
									</span>
									<span className="">{article.titre_fr}</span>
								</div>
								<div className="flex flex-col h-full px-2">
									<span className="whitespace-nowrap font-bold capitalize">
										extrait :
									</span>
									<span className="px-2">{article.extrait_fr}</span>
								</div>
								<div className="flex w-full gap-2 p-2">
									<button
										className="grid grid-cols-[auto_1fr] rounded-md bg-indigo-600 px-3 py-2 mx-auto text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 whitespace-nowrap items-center justify-between w-full"
										onClick={() => {
											handleSelectArticle(article);
										}}
									>
										<div className="">
											<PencilIcon className="w-4 h-4" />
										</div>
										<div className="">Modifier</div>
									</button>
									<button
										className="grid grid-cols-[auto_1fr] rounded-md bg-rose-600 px-3 py-2  mx-auto text-sm font-semibold text-white shadow-sm hover:bg-rose-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-rose-600 whitespace-nowrap items-center justify-between w-full"
										onClick={() => {}}
									>
										<div className="">
											<TrashIcon className="w-4 h-4" />
										</div>
										<div className="">Supprimer</div>
									</button>
								</div>
							</div>
						</React.Fragment>
					);
				})}
			</div>
		</>
	);
};

export default ArticleList;
