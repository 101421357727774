import React, { useEffect, useState } from "react";
import { Media } from "src/api/medias/mediaTypes";
import { checkImageExists } from "src/lib/functions";
import SafeImage from "../images/SafeImage";
import { StarIcon, TrashIcon } from "@heroicons/react/24/solid";
import { StarIcon as OutStarIcon } from "@heroicons/react/24/outline";

function ImageForm({
	repertoire,
	handleFormSubmit,
	handleUpdateFile,
	handleDeleteFile,
	//setFiles,
	className,
	media,
	selectedMediaId
}: {
	repertoire: string;
	handleFormSubmit: (files: FileList | null) => void;
	handleUpdateFile: (mediaId: number) => void;
	handleDeleteFile: (mediaId: number) => void;

	//setFiles: (files: FileList | null) => void;
	className?: string;
	media: Media[];
	selectedMediaId: number;
}) {
	const [files, setFiles] = useState<FileList | null>(null);
	const [preview, setPreview] = useState<string | null>(null);
	const [dragActive, setDragActive] = useState(false);
	const [selectedGrilles, setSelectedGrilles] = useState([]);

	const [imageStates, setImageStates] = useState<{ [key: string]: boolean }>(
		{}
	);
	useEffect(() => {
		if (files && files.length > 0) {
			const file = files[0];
			const reader = new FileReader();

			reader.onloadend = () => {
				setPreview(reader.result as string);
			};

			reader.readAsDataURL(file);
		} else {
			setPreview(null);
		}
	}, [files]);

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setFiles(event.target.files);
	};

	const handleDragOver = (event: React.DragEvent) => {
		event.preventDefault();
		setDragActive(true);
	};

	const handleDragLeave = (event: React.DragEvent) => {
		event.preventDefault();
		setDragActive(false);
	};

	const handleDrop = (event: React.DragEvent) => {
		event.preventDefault();
		setDragActive(false);
		setFiles(event.dataTransfer.files);
	};

	const handleDivClick = () => {
		const fileInput = document.getElementById("fileInput") as HTMLInputElement;
		fileInput.click();
	};

	useEffect(() => {
		const loadImages = async () => {
			if (media) {
				const newImageStates: { [key: string]: boolean } = {};
				for (let i = 0; i < media.length; i++) {
					const med = media[i];
					const image = await checkImageExists(
						`https://punch-croisieres.com/assets/media/${repertoire}/${med.fichier}`
					);

					newImageStates[med.fichier] = image;
				}
				setImageStates(newImageStates);
			}
		};

		loadImages();
	}, [media]);
	return (
		<div className={`${className}  flex-col w-[300px]"`}>
			<div className="flex flex-col bg-gray-100 p-4 rounded-md">
				<h2 className="text-lg font-medium text-gray-700 mb-2">
					Téléchargement des fichiers
				</h2>
				<form
					onSubmit={(e) => {
						e.preventDefault();
						handleFormSubmit(files);
						setFiles(null);
					}}
					encType="multipart/form-data"
				>
					<input
						type="file"
						id="fileInput"
						name="files"
						multiple
						onChange={handleFileChange}
						style={{ display: "none" }} // Hide the default file input
					/>
					<div
						className={`border-dashed border-2 rounded-md p-6 text-center ${
							dragActive ? "border-blue-500" : "border-gray-300"
						}`}
						onClick={handleDivClick}
						onDragOver={handleDragOver}
						onDragLeave={handleDragLeave}
						onDrop={handleDrop}
					>
						<p className="text-gray-600">
							Sélectionner des fichiers ou déposer ici
						</p>
					</div>

					{preview && (
						<div className="mt-4">
							<img
								src={preview}
								alt="Preview"
								className="max-w-full h-auto rounded-md"
							/>
						</div>
					)}
					<button
						className="w-full mt-2 rounded-md text-white bg-blue-500 p-2"
						type="submit"
					>
						Upload
					</button>
				</form>
			</div>
			<div className="flex flex-col bg-gray-100 p-4 rounded-md mt-4">
				<h2 className="text-lg font-medium text-gray-700 mb-2">
					Liste des fichiers
				</h2>
				<div className="flex flex-col gap-2 items-center justify-center">
					{/* Dynamically render the list of uploaded files */}
					{(media ?? [])
						.sort(
							(a, b) =>
								// parseInt(moment(b.created_at).format("YYYYMMDDHHmm")) -
								// parseInt(moment(a.created_at).format("YYYYMMDDHHmm"))
								b.id - a.id
						)
						.map((med, media_index) => {
							const imageKey = med.fichier;
							const imageExists = imageStates[imageKey];

							if (med.id === 0) {
								return <></>;
							}
							return (
								<React.Fragment key={media_index}>
									<div className="flex flex-row w-full gap-2 justify-between text-xs shadow-[0_0px_10px_1px_rgba(0,0,0,0.3)] bg-white p-2 rounded-md">
										<div className=" grow w-36 overflow-hidden items-center justify-center shadow-md">
											{imageExists ? (
												<SafeImage
													src={`https://punch-croisieres.com/assets/media/${repertoire}/${med.fichier}`}
													alt="Example Image"
													className="min-w-32"
												/>
											) : (
												<div className="flex bg-white p-3 w-full h-40 text-center text-xs items-center justify-center">
													Image introuvable (
													{`https://punch-croisieres.com/assets/media/${repertoire}/${med.fichier}`}
													)
												</div>
											)}
										</div>
										<div className="flex flex-col ">
											<button
												className={`flex flex-row items-center justify-between gap-2 h-10 mt-2 rounded-md text-white p-2 ${
													selectedMediaId === med.id
														? "bg-yellow-500"
														: "bg-gray-500"
												} `}
												disabled={selectedMediaId === med.id}
												onClick={() => {
													handleUpdateFile(med.id);
												}}
											>
												<div className="">Favorie</div>
												<div className="">
													{selectedMediaId === med.id ? (
														<StarIcon className="h-6 w-6" />
													) : (
														<OutStarIcon className="h-6 w-6" />
													)}
												</div>
											</button>
											<button
												className="flex flex-row items-center justify-between gap-2 h-10 mt-2 rounded-md text-white bg-rose-500 p-2"
												onClick={() => {
													handleDeleteFile(med.id);
												}}
											>
												<div className="">Supprimer</div>
												<div className="">
													<TrashIcon className="h-6 w-6" />
												</div>
											</button>
										</div>
									</div>
								</React.Fragment>
							);
						})}
				</div>
			</div>
		</div>
	);
}

export default ImageForm;
