export class User {
	id?: number;
	email: string = "";
	nom: string = "";
	prenom: string = "";
	password?: string = "";
	confirmPassword?: string = "";
	role: number = 3;
	// Ajoutez d'autres champs si nécessaire
}
