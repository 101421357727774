import * as enums from "./enums";
import { ReactElement } from "react";

export type Alignment = "start" | "end";
export type Side = "top" | "right" | "bottom" | "left";
export type AlignedPlacement = `${Side}-${Alignment}`;
export type Placement = Side | AlignedPlacement;
export type Strategy = "absolute" | "fixed";
export type Axis = "x" | "y";
export type Length = "width" | "height";
export type SideObject = {
	[key in Side]: number;
};

export class RoomOption {
	showWaiterSector = true;
	showTimer = false;
}

export interface Section {
	id: string;
	title: string;
	value?: string;
	content: React.ReactNode | string;
	slug: string;
}

export class AppConfig {}
export class DashboardSetting {
	showEnded = true;
	showDeleted = true;
	showOpened = true;
}
export class AppTheme {
	headerTheme: AppBackGround = "bg-info";
	sideBarTheme: AppBackGround = "bg-info";
}

export type sizeList = "16" | "24" | "32" | "40" | "45" | "50" | "60";

export type AppBackGround =
	| "bg-primary"
	| "bg-secondary"
	| "bg-success"
	| "bg-info"
	| "bg-warning"
	| "bg-danger"
	| "bg-light"
	| "bg-dark"
	| "bg-focus"
	| "bg-alternate"
	| "bg-vicious-stance"
	| "bg-midnight-bloom"
	| "bg-night-sky"
	| "bg-slick-carbon"
	| "bg-asteroid"
	| "bg-royal"
	| "bg-warm-flame"
	| "bg-night-fade"
	| "bg-sunny-morning"
	| "bg-tempting-azure"
	| "bg-amy-crisp"
	| "bg-heavy-rain"
	| "bg-mean-fruit"
	| "bg-malibu-beach"
	| "bg-deep-blue"
	| "bg-ripe-malin"
	| "bg-arielle-smile"
	| "bg-plum-plate"
	| "bg-happy-fisher"
	| "bg-happy-itmeo"
	| "bg-mixed-hopes"
	| "bg-strong-bliss"
	| "bg-grow-early"
	| "bg-love-kiss"
	| "bg-premium-dark"
	| "bg-happy-green"
	| string;
