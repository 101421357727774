import { ListItemIcon } from "@mui/material";
import { ListBulletIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import React from "react";
import { useNavigate } from "react-router-dom";
import * as enums from "src/lib/enums";
import { Duree } from "src/api/durees/dureeTypes";

function GrilleTarifMenu() {
	const navigate = useNavigate();
	return (
		<div className="flex flex-row gap-3 items-center p-2 bg-blue-200/20 shadow-[0_5px_10px_-5px_rgba(0,0,0,0.3)]">
			<div
				className="flex flex-row gap-2 items-center p-2 cursor-pointer"
				onClick={() => {
					navigate(enums.PAGES_TARIF_LIST1);
				}}
			>
				<ListBulletIcon className="w-8 h-8" /> Tarifs courte durée
			</div>
			<div
				className="flex flex-row gap-2 items-center p-2 cursor-pointer"
				onClick={() => {
					navigate(enums.PAGES_TARIF_LIST2);
				}}
			>
				<ListBulletIcon className="w-8 h-8" /> Tarifs longue durée
			</div>
			<div
				className="flex flex-row gap-2 items-center p-2 cursor-pointer"
				onClick={() => {
					navigate(enums.PAGES_SAISON_LIST);
				}}
			>
				<ListBulletIcon className="w-8 h-8" /> Liste des saisons
			</div>
			<div
				className="flex flex-row gap-2 items-center p-2 cursor-pointer"
				onClick={() => {
					navigate(enums.PAGES_SAISON_ADD);
				}}
			>
				<PlusCircleIcon className="w-8 h-8" />
				Ajouter un saison
			</div>
		</div>
	);
}

export default GrilleTarifMenu;
