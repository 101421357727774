import axiosInstance from "../axiosConfig";
import { Escale } from "./escaleTypes";
export const fetchEscales = async () => {
	const response = await axiosInstance.get("/escales");
	return response.data;
};

export const getNbEscales = async () => {
	const response = await axiosInstance.get(`/escales/count`);
	return response.data;
};

export const fetchEscaleById = async (id: string) => {
	const response = await axiosInstance.get(`/escales/${id}`);
	return response.data;
};

export const createEscale = async (escale: Escale) => {
	const response = await axiosInstance.post("/escales", {
		...escale,
		type_id: escale.type_id ?? 0
	});
	return response;
};

export const updateEscale = async (id: number, escale: Escale) => {
	const response = await axiosInstance.put(`/escales/${id}`, {
		...escale,
		type_id: escale.type_id ?? 0
	});
	return response;
};

export const deleteEscale = async (id: number) => {
	await axiosInstance.delete(`/escales/${id}`);
};

// Upload a file (e.g., image, video, etc.)
export const escaleFileUpload = async (file: File, escaleId?: number) => {
	if (escaleId) {
		const formData = new FormData();
		formData.append("file", file);
		formData.append("escaleId", escaleId.toString());

		// Assuming your API endpoint for file uploads is /upload
		const response = await axiosInstance.post("/escales/upload", formData, {
			headers: {
				"Content-Type": "multipart/form-data"
			}
		});

		return response; // Assuming the response includes the uploaded file's ID or URL
	}
};
export const deleteEscaleFile = async (escaleId: number, typeId: number) => {
	const response = await axiosInstance.delete(
		`/escales/${escaleId}/type/${typeId}`
	);

	return response;
};
