import React, { useEffect, useState } from "react";
import { fetchUsers } from "../../api/users/userService";
import { User } from "../../api/users/userTypes";

const UserList = ({
	handleSelectUser
}: {
	handleSelectUser: (user?: User) => void;
}) => {
	const [users, setUsers] = useState<User[]>([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const loadUsers = async () => {
			try {
				const data = await fetchUsers();

				setUsers(data);
			} catch (error) {
				console.error("Failed to fetch users", error);
			} finally {
				setLoading(false);
			}
		};

		loadUsers();
	}, []);

	if (loading) return <p>Loading...</p>;

	return (
		<div className="grid grid-rows-[1fr] w-full h-full overflow-hidden">
			<div className="flex flex-col h-full w-full max-w-5xl m-auto overflow-y-auto">
				{users.map((user) => (
					<React.Fragment key={user.id}>
						<div className="grid grid-cols-[1fr_3fr_auto_auto] w-full">
							<div className="">
								{user.nom} {user.prenom}
							</div>
							<div className="">{user.email}</div>
							<div className=""></div>
							<div className=""></div>
						</div>
					</React.Fragment>
				))}
			</div>
		</div>
	);
};

export default UserList;
