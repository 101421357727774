import axiosInstance from "../axiosConfig";
import { Saison } from "./saisonTypes";

export const fetchSaisons = async () => {
	const response = await axiosInstance.get("/saisons");
	return response.data;
};

export const fetchSaisonById = async (id: number) => {
	const response = await axiosInstance.get(`/saisons/${id}`);
	return response;
};

export const createSaison = async (saison: Saison) => {
	const response = await axiosInstance.post("/saisons", saison);
	return response;
};

export const updateSaison = async (id: number, saison: Saison) => {
	const response = await axiosInstance.put(`/saisons/${id}`, saison);
	return response;
};

export const deleteSaison = async (id: number) => {
	await axiosInstance.delete(`/saisons/${id}`);
};
