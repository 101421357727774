import React, { useEffect, useState } from "react";

import moment from "moment";
import TarifMenu from "./TarifMenu";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import SafeImage from "../images/SafeImage";
import { checkImageExists } from "src/lib/functions";
import { Tarif } from "src/api/tarifs/tarifTypes";
import { fetchTarifs, bulkUpdateTarif } from "src/api/tarifs/tarifService";
import { fetchSaisons } from "src/api/saisons/saisonService";
import { Saison } from "src/api/saisons/saisonTypes";
import { fetchDurees } from "src/api/durees/dureeService";
import { Duree } from "src/api/durees/dureeTypes";
import { Bateau } from "src/api/bateaux/bateauTypes";

const TarifList = ({ grilleId }: { grilleId: number }) => {
	const navigate = useNavigate();
	const [tarifs, setTarifs] = useState<Tarif[]>([]);
	const [saisons, setSaisons] = useState<Saison[]>([]);
	const [durees, setDurees] = useState<Duree[]>([]);
	const [loadingTarifs, setLoadingTarifs] = useState(true);
	const [loadingSaisons, setLoadingSaisons] = useState(true);
	const [loadingDurees, setLoadingDurees] = useState(true);

	const currSaisonsId: number[] = [];

	const [bateaux, setBateaux] = useState<any>([]);
	const [bateauxId, setbateauxId] = useState<number[]>([]);
	const [bateauxNoSeason, setBateauxNoSeason] = useState<any>([]);

	const currDuree = durees.filter((duree) => duree.grille_id === grilleId);

	const handleUpdateTarif = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.currentTarget;
		const idz = name.split("_");
		const newBoats = { ...bateaux };
		const newBoatsNoSeason = { ...bateauxNoSeason };
		if (grilleId === 1) {
			newBoats[idz[0]].saisons[idz[1]].durees[idz[2]].montant = value;
			setBateaux(newBoats);
		} else {
			newBoatsNoSeason[idz[0]].durees[idz[2]].montant = value;
			setBateauxNoSeason(newBoatsNoSeason);
		}
	};

	const updateBateauxTarif = () => {
		const itemsToUpdate: Tarif[] = [];
		if (grilleId === 1) {
			bateauxId.map((bateauId: any) => {
				const bateau = bateaux[bateauId];
				bateaux[bateauId].saisons.map((bateau_saison: any) => {
					bateau_saison.durees.map((bateau_saison_duree: any) => {
						itemsToUpdate.push({
							grille_id: grilleId,
							bateau_id: bateau.id,
							saison_id: bateau_saison.id,
							duree_id: bateau_saison_duree.id,
							montant: bateau_saison_duree.montant
						});
					});
				});
			});
		} else if (grilleId === 2) {
			bateauxId.map((bateauId: any) => {
				const bateau = bateauxNoSeason[bateauId];
				bateau.durees.map((bateau_duree: any) => {
					itemsToUpdate.push({
						grille_id: grilleId,
						bateau_id: bateau.id,
						saison_id: 0,
						duree_id: bateau_duree.id,
						montant: bateau_duree.montant
					});
				});
			});
		}
		if (itemsToUpdate.length > 0) {
			bulkUpdateTarif(itemsToUpdate);
		}
	};

	useEffect(() => {
		if (!loadingTarifs && !loadingSaisons && !loadingDurees) {
			const tmp_bateauxId: number[] = [];
			const tmp_bateaux: any = [];
			const tmp_bateauxNoSeason: any = [];
			[...tarifs]
				.filter((trf) => {
					const verif =
						trf.grille_id === grilleId &&
						((trf.saison_id !== 0 && grilleId === 1) ||
							(trf.saison_id === 0 && grilleId === 2));

					return verif;
				})
				.map((tarif) => {
					if (!currSaisonsId.includes(tarif.saison_id)) {
						currSaisonsId.push(tarif.saison_id);
					}
					if (!tmp_bateauxId.includes(tarif.bateau_id)) {
						tmp_bateauxId.push(tarif.bateau_id);
					}

					if (!tmp_bateaux[tarif.bateau_id]) {
						tmp_bateaux[tarif.bateau_id] = {
							nom: tarif.bateau?.titre_fr,
							id: tarif.bateau_id,
							saisons: []
						};
						tmp_bateauxNoSeason[tarif.bateau_id] = {
							nom: tarif.bateau?.titre_fr,
							id: tarif.bateau_id,
							durees: []
						};
					}

					if (!tmp_bateaux[tarif.bateau_id].saisons[tarif.saison_id]) {
						tmp_bateaux[tarif.bateau_id].saisons[tarif.saison_id] = {
							...tarif.saison,
							durees: []
						};
					}
					const duree = { ...tarif.duree, montant: tarif.montant };
					const test = tmp_bateaux[tarif.bateau_id].saisons[
						tarif.saison_id
					].durees.find((dur: Duree) => {
						if (!dur) return false;
						return dur && dur.id && duree.id && dur.id === duree.id;
					});
					const test2 = tmp_bateauxNoSeason[tarif.bateau_id].durees.find(
						(dur: Duree) => {
							if (!dur) return false;
							return dur && dur.id && duree.id && dur.id === duree.id;
						}
					);

					if (duree && duree.id && !test) {
						tmp_bateaux[tarif.bateau_id].saisons[tarif.saison_id].durees[
							duree.id
						] = duree;
					}

					if (duree && duree.id && !test2) {
						tmp_bateauxNoSeason[tarif.bateau_id].durees[duree.id] = duree;
					}
					return tarif;
				});

			setbateauxId(tmp_bateauxId);
			setBateaux(tmp_bateaux);
			setBateauxNoSeason(tmp_bateauxNoSeason);
		}
	}, [loadingTarifs, loadingSaisons, loadingDurees, grilleId, tarifs]);

	useEffect(() => {
		const loadTarifs = async () => {
			try {
				const data = (await fetchTarifs()) as Tarif[];
				setTarifs(data);
			} catch (error: any) {
				debugger;
				if (error.response.data === "Failed to authenticate token.") {
					navigate("/login");
				}

				console.error("Failed to fetch tarifs", error);
			} finally {
				setLoadingTarifs(false);
			}
		};

		const loadSaisons = async () => {
			try {
				const data = (await fetchSaisons()) as Saison[];
				setSaisons(data);
			} catch (error: any) {
				debugger;
				if (error.response.data === "Failed to authenticate token.") {
					navigate("/login");
				}

				console.error("Failed to fetch saisons", error);
			} finally {
				setLoadingSaisons(false);
			}
		};

		const loadDurees = async () => {
			try {
				const data = (await fetchDurees()) as Duree[];
				setDurees(data);
			} catch (error: any) {
				debugger;
				if (error.response.data === "Failed to authenticate token.") {
					navigate("/login");
				}

				console.error("Failed to fetch duree", error);
			} finally {
				setLoadingDurees(false);
			}
		};

		loadSaisons();
		loadDurees();
		loadTarifs();
	}, [grilleId]);
	if (loadingTarifs && loadingSaisons && loadingDurees)
		return <p>Loading...</p>;

	return (
		<>
			<div
				className={`grid grid-cols-[${
					grilleId === 1 ? "auto_1fr_1fr_1fr" : "auto_1fr"
				}] grid-rows-[auto-auto-1fr] w-[500px] h-full mb-auto gap-2`}
			>
				{grilleId === 1 ? (
					<>
						<div className=""></div>
						<div className="flex justify-center items-center p-1 bg-gray-200">
							Basse Saison
						</div>
						<div className="flex justify-center items-center p-1 bg-gray-200">
							Moyenne saison
						</div>
						<div className="flex justify-center items-center p-1 bg-gray-200">
							Haute saison
						</div>
					</>
				) : (
					<></>
				)}

				<div className=""></div>
				<div className="flex flex-row w-full gap-1">
					{currDuree.map((duree, d_id) => (
						<React.Fragment key={d_id}>
							<div className="flex justify-center items-center p-1 bg-gray-200 w-full">
								{duree.nom}
							</div>
						</React.Fragment>
					))}
				</div>
				{grilleId === 1 ? (
					<>
						<div className="flex flex-row w-full gap-1">
							{currDuree.map((duree, d_id) => (
								<React.Fragment key={d_id}>
									<div className="flex justify-center items-center p-1 bg-gray-200 w-full">
										{duree.nom}
									</div>
								</React.Fragment>
							))}
						</div>
						<div className="flex flex-row w-full gap-1">
							{currDuree.map((duree, d_id) => (
								<React.Fragment key={d_id}>
									<div className="flex justify-center items-center p-1 bg-gray-200 w-full">
										{duree.nom}
									</div>
								</React.Fragment>
							))}
						</div>
					</>
				) : (
					<></>
				)}

				{bateauxId.map((bateauId) => {
					const bateau = bateaux[bateauId];
					const bateauNoSeason = bateauxNoSeason[bateauId];
					if (!bateau) return;

					const basse = bateau.saisons.find(
						(saison: Saison) => saison && saison.nom === "Basse saison"
					);
					const moyenne = bateau.saisons.find(
						(saison: Saison) => saison && saison.nom === "Moyenne saison"
					);
					const haute = bateau.saisons.find(
						(saison: Saison) => saison && saison.nom === "Haute saison"
					);

					return (
						<React.Fragment key={bateauId}>
							{grilleId === 1 ? (
								<>
									<div className="flex whitespace-nowrap px-2 bg-gray-200 items-center">
										{bateau.nom}
									</div>
									<div
										className={`grid  grid-flow-col auto-cols-max gap-2 items-center`}
									>
										{(basse ?? { durees: [] }).durees.map((duree: any) => {
											return (
												<React.Fragment key={duree.id}>
													<div className="flex items-center">
														<input
															className="flex w-[100px] p-1 border-[1px] border-gray-300 rounded-md"
															type="text"
															value={duree.montant}
															name={`${bateau.id}_${basse.id}_${duree.id}`}
															onChange={(e) => {
																handleUpdateTarif(e);
															}}
														/>
													</div>
												</React.Fragment>
											);
										})}
									</div>
									<div
										className={`grid  grid-flow-col auto-cols-max gap-2 items-center`}
									>
										{(moyenne ?? { durees: [] }).durees.map((duree: any) => {
											return (
												<React.Fragment key={duree.id}>
													<div className="flex items-center">
														<input
															className="flex w-[100px] p-1 border-[1px] border-gray-300 rounded-md"
															type="text"
															value={duree.montant}
															name={`${bateau.id}_${moyenne.id}_${duree.id}`}
															onChange={(e) => {
																handleUpdateTarif(e);
															}}
														/>
													</div>
												</React.Fragment>
											);
										})}
									</div>
									<div
										className={`grid  grid-flow-col auto-cols-max gap-2 items-center`}
									>
										{(haute ?? { durees: [] }).durees.map((duree: any) => {
											return (
												<React.Fragment key={duree.id}>
													<div className="flex items-center">
														<input
															className="flex w-[100px] p-1 border-[1px] border-gray-300 rounded-md"
															type="text"
															value={duree.montant}
															name={`${bateau.id}_${haute.id}_${duree.id}`}
															onChange={(e) => {
																handleUpdateTarif(e);
															}}
														/>
													</div>
												</React.Fragment>
											);
										})}
									</div>
								</>
							) : (
								<>
									<div className="flex whitespace-nowrap px-2 bg-gray-200 items-center">
										{bateauNoSeason.nom}
									</div>
									<div
										className={`grid  grid-flow-col auto-cols-max gap-2 items-center`}
									>
										{bateauNoSeason.durees.map((duree: any) => {
											return (
												<React.Fragment key={duree.id}>
													<div className="flex items-center">
														<input
															className="flex w-[100px] p-1 border-[1px] border-gray-300 rounded-md"
															type="text"
															value={duree.montant}
															name={`${bateau.id}_0_${duree.id}`}
															onChange={(e) => {
																handleUpdateTarif(e);
															}}
														/>
													</div>
												</React.Fragment>
											);
										})}
									</div>
								</>
							)}
						</React.Fragment>
					);
				})}
			</div>
			<div className=" p-2">
				<button
					onClick={() => {
						updateBateauxTarif();
					}}
					className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 disabled:bg-gray-400 p-2"
				>
					Modifier
				</button>
			</div>
			{/* <div className="grid grid-cols-2 w-full text-sm max-w-5xl h-full m-auto overflow-y-auto pb-10 mt-2 gap-3">
				{tarifs
					.filter((trf) => trf.grille_id === grilleId && trf.saison_id !== 0)
					.map((tarif) => {
						return (
							<React.Fragment key={tarif.id}>
								<div
									className={`relative grid grid-rows-[auto_1fr_auto] shadow-md rounded-md items-center odd:bg-gray-200 even:bg-gray-50`}
								>
									<div className="flex h-full px-2 gap-2">
										<span className="whitespace-nowrap font-bold capitalize">
											lieu :
										</span>
										<span className="">
											{tarif.type?.nom ? `${tarif.type?.nom} de` : ""}{" "}
											{tarif.lieu}
										</span>
									</div>
									<div className="flex flex-col h-full px-2">
										<span className="font-bold capitalize">Description :</span>
										<span className="px-2">{tarif.description}</span>
									</div>
									<div className="flex w-full gap-2 p-2">
										<button
											className="grid grid-cols-[auto_1fr] rounded-md bg-indigo-600 px-3 py-2 mx-auto text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 whitespace-nowrap items-center justify-between w-full"
											onClick={() => {
												handleSelectTarif(tarif);
											}}
										>
											<div className="">
												<PencilIcon className="w-4 h-4" />
											</div>
											<div className="">Modifier</div>
										</button>
										<button
											className="grid grid-cols-[auto_1fr] rounded-md bg-rose-600 px-3 py-2  mx-auto text-sm font-semibold text-white shadow-sm hover:bg-rose-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-rose-600 whitespace-nowrap items-center justify-between w-full"
											onClick={() => {}}
										>
											<div className="">
												<TrashIcon className="w-4 h-4" />
											</div>
											<div className="">Supprimer</div>
										</button>
									</div>
								</div>
							</React.Fragment>
						);
					})}
			</div> */}
		</>
	);
};

export default TarifList;
