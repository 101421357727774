import { ListBulletIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import React from "react";
import { useNavigate } from "react-router-dom";
import { User } from "src/api/users/userTypes";
import * as enums from "src/lib/enums";

const UserMenu = ({
	handleSelectUser
}: {
	handleSelectUser: (user?: User) => void;
}) => {
	const navigate = useNavigate();
	return (
		<div className="flex flex-row gap-3 items-center p-2 bg-blue-200/20 shadow-[0_5px_10px_-5px_rgba(0,0,0,0.3)]">
			<div
				className="flex flex-row gap-2 items-center p-2 cursor-pointer"
				onClick={() => {
					navigate(enums.PAGES_USER_LIST);
				}}
			>
				<ListBulletIcon className="w-8 h-8" /> Liste des utilisateurs
			</div>
			<div
				className="flex flex-row gap-2 items-center p-2 cursor-pointer"
				onClick={() => {
					handleSelectUser(undefined);
				}}
			>
				<PlusCircleIcon className="w-8 h-8" />
				Ajouter un utilisateur
			</div>
		</div>
	);
};

export default UserMenu;
