import React from "react";

const AnnonceIcon = ({ className }: { className?: string }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			className={`${className}`}
			viewBox="0 0 368.912 352.156"
		>
			<g
				id="Groupe_9"
				data-name="Groupe 9"
				transform="translate(17327.035 453.059)"
			>
				<path
					id="Tracé_16"
					data-name="Tracé 16"
					d="M-17126.623,133.1h-.094l-326.553-4.689a6.483,6.483,0,0,1-2.3-.455c-21.494-1.876-27.75-19.329-30.158-26.047-.162-.448-.3-.854-.43-1.2-2.469-2.533-4.066-7.055-1.748-232.838a6.5,6.5,0,0,1,6.5-6.433h50.547v-73.991a6.5,6.5,0,0,1,6.5-6.5h250.07a6.5,6.5,0,0,1,4.217,1.553l47.668,40.636a6.5,6.5,0,0,1,2.283,4.947V126.6a6.5,6.5,0,0,1-1.937,4.629A6.5,6.5,0,0,1-17126.623,133.1Zm-298.369-17.285,291.869,4.191V-168.922l-43.562-37.136h-241.176V99.246a6.524,6.524,0,0,1-.082,1.032A35,35,0,0,1-17424.992,115.813Zm-50.326-22.46a22.958,22.958,0,0,1,1.824,4.166c2.971,8.285,7.268,17.16,20.732,17.583.33.01.658.015.977.015,16.66,0,20.357-13.76,20.924-16.467V-125.568h-44.111C-17475.8-42.267-17476.514,77.531-17475.318,93.353Z"
					transform="translate(162 -234)"
					fill="inherit"
				/>
				<path
					id="Ligne_1"
					data-name="Ligne 1"
					d="M99,7.5h-.067l-99-1A6.5,6.5,0,0,1-6.5-.066,6.5,6.5,0,0,1,.066-6.5l99,1A6.5,6.5,0,0,1,99,7.5Z"
					transform="translate(-17231.5 -415.5)"
					fill="inherit"
				/>
				<path
					id="Ligne_2"
					data-name="Ligne 2"
					d="M140,6.5H0A6.5,6.5,0,0,1-6.5,0,6.5,6.5,0,0,1,0-6.5H140A6.5,6.5,0,0,1,146.5,0,6.5,6.5,0,0,1,140,6.5Z"
					transform="translate(-17231.5 -390.5)"
					fill="inherit"
				/>
				<path
					id="Ligne_3"
					data-name="Ligne 3"
					d="M219,6.5H0A6.5,6.5,0,0,1-6.5,0,6.5,6.5,0,0,1,0-6.5H219A6.5,6.5,0,0,1,225.5,0,6.5,6.5,0,0,1,219,6.5Z"
					transform="translate(-17231.5 -366.5)"
					fill="inherit"
				/>
				<path
					id="Ligne_6"
					data-name="Ligne 6"
					d="M58,6.5H0A6.5,6.5,0,0,1-6.5,0,6.5,6.5,0,0,1,0-6.5H58A6.5,6.5,0,0,1,64.5,0,6.5,6.5,0,0,1,58,6.5Z"
					transform="translate(-17231.5 -237.5)"
					fill="inherit"
				/>
				<path
					id="Ligne_7"
					data-name="Ligne 7"
					d="M58,6.5H0A6.5,6.5,0,0,1-6.5,0,6.5,6.5,0,0,1,0-6.5H58A6.5,6.5,0,0,1,64.5,0,6.5,6.5,0,0,1,58,6.5Z"
					transform="translate(-17231.5 -198.5)"
					fill="inherit"
				/>
				<path
					id="Ligne_8"
					data-name="Ligne 8"
					d="M43,6.5H0A6.5,6.5,0,0,1-6.5,0,6.5,6.5,0,0,1,0-6.5H43A6.5,6.5,0,0,1,49.5,0,6.5,6.5,0,0,1,43,6.5Z"
					transform="translate(-17231.5 -167.5)"
					fill="inherit"
				/>
				<path
					id="Ligne_9"
					data-name="Ligne 9"
					d="M62,6.5H0A6.5,6.5,0,0,1-6.5,0,6.5,6.5,0,0,1,0-6.5H62A6.5,6.5,0,0,1,68.5,0,6.5,6.5,0,0,1,62,6.5Z"
					transform="translate(-17231.5 -142.5)"
					fill="inherit"
				/>
				<path
					id="Ligne_10"
					data-name="Ligne 10"
					d="M101,6.5H0A6.5,6.5,0,0,1-6.5,0,6.5,6.5,0,0,1,0-6.5H101A6.5,6.5,0,0,1,107.5,0,6.5,6.5,0,0,1,101,6.5Z"
					transform="translate(-17142.5 -167.5)"
					fill="inherit"
				/>
				<path
					id="Ligne_11"
					data-name="Ligne 11"
					d="M145,6.5H0A6.5,6.5,0,0,1-6.5,0,6.5,6.5,0,0,1,0-6.5H145A6.5,6.5,0,0,1,151.5,0,6.5,6.5,0,0,1,145,6.5Z"
					transform="translate(-17142.5 -142.5)"
					fill="inherit"
				/>
				<path
					id="Ligne_12"
					data-name="Ligne 12"
					d="M0,223.5A6.5,6.5,0,0,1-6.5,217V0A6.5,6.5,0,0,1,0-6.5,6.5,6.5,0,0,1,6.5,0V217A6.5,6.5,0,0,1,0,223.5Z"
					transform="translate(-17295.5 -363.5)"
					fill="inherit"
				/>
				<path
					id="Tracé_17"
					data-name="Tracé 17"
					d="M-17393.5-107h234.838a6.5,6.5,0,0,1,6.5,6.5v64.553a6.5,6.5,0,0,1-6.5,6.5H-17393.5a6.5,6.5,0,0,1-6.5-6.5V-100.5A6.5,6.5,0,0,1-17393.5-107Zm228.338,13H-17387v51.553h221.838Z"
					transform="translate(162 -234)"
					fill="inherit"
				/>
				<path
					id="Tracé_18"
					data-name="Tracé 18"
					d="M-17393.5-107h58a6.5,6.5,0,0,1,6.5,6.5v39a6.5,6.5,0,0,1-6.5,6.5h-58a6.5,6.5,0,0,1-6.5-6.5v-39A6.5,6.5,0,0,1-17393.5-107Zm51.5,13h-45v26h45Z"
					transform="translate(162 -137)"
					fill="inherit"
				/>
				<path
					id="Tracé_19"
					data-name="Tracé 19"
					d="M-17393.5-107h58a6.5,6.5,0,0,1,6.5,6.5v39a6.5,6.5,0,0,1-6.5,6.5h-58a6.5,6.5,0,0,1-6.5-6.5v-39A6.5,6.5,0,0,1-17393.5-107Zm51.5,13h-45v26h45Z"
					transform="translate(251 -137)"
					fill="inherit"
				/>
				<path
					id="Tracé_20"
					data-name="Tracé 20"
					d="M-17393.5-107h58a6.5,6.5,0,0,1,6.5,6.5v39a6.5,6.5,0,0,1-6.5,6.5h-58a6.5,6.5,0,0,1-6.5-6.5v-39A6.5,6.5,0,0,1-17393.5-107Zm51.5,13h-45v26h45Z"
					transform="translate(339 -137)"
					fill="inherit"
				/>
				<path
					id="Tracé_21"
					data-name="Tracé 21"
					d="M-17127.4-164.641h-47.1a6.5,6.5,0,0,1-6.5-6.5v-41.418a6.5,6.5,0,0,1,6.5-6.5,6.5,6.5,0,0,1,6.5,6.5v34.918h40.6a6.5,6.5,0,0,1,6.5,6.5A6.5,6.5,0,0,1-17127.4-164.641Z"
					transform="translate(162 -234)"
					fill="inherit"
				/>
			</g>
		</svg>
	);
};

export default AnnonceIcon;
