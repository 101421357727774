import axiosInstance from "../axiosConfig";
import { Tarif } from "./tarifTypes";

export const fetchTarifs = async () => {
	const response = await axiosInstance.get("/tarifs");
	return response.data;
};

export const fetchTarifById = async (id: string) => {
	const response = await axiosInstance.get(`/tarifs/${id}`);
	return response.data;
};

export const createTarif = async (tarif: Tarif) => {
	const response = await axiosInstance.post("/tarifs", tarif);
	return response.data;
};

export const updateTarif = async (id: string, tarif: Tarif) => {
	const response = await axiosInstance.put(`/tarifs/${id}`, tarif);
	return response.data;
};

export const bulkUpdateTarif = async (tarifs: Tarif[]) => {
	const response = await axiosInstance.put(`/tarifs/`, tarifs);
	return response.data;
};

export const deleteTarif = async (id: string) => {
	await axiosInstance.delete(`/tarifs/${id}`);
};
