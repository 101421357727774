import axiosInstance from "../axiosConfig";
import { Duree } from "./dureeTypes";

export const fetchDurees = async () => {
	const response = await axiosInstance.get("/durees");
	return response.data;
};

export const fetchDureeById = async (id: number) => {
	const response = await axiosInstance.get(`/durees/${id}`);
	return response.data;
};

export const createDuree = async (duree: Duree) => {
	const response = await axiosInstance.post("/durees", duree);
	return response.data;
};

export const updateDuree = async (id: number, duree: Duree) => {
	const response = await axiosInstance.put(`/durees/${id}`, duree);
	return response.data;
};

export const deleteDuree = async (id: number) => {
	await axiosInstance.delete(`/durees/${id}`);
};
