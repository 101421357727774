import { Media } from "../medias/mediaTypes";
import { Gamme } from "./gammeTypes";
import { Grille } from "./grilleTypes";

export class Bateau {
	id: number = 0;
	titre_fr: string = "";
	titre_en: string = "";
	slug: string = "";
	texte_fr: string = "";
	texte_en: string = "";
	texte_md_fr: string = "";
	texte_md_en: string = "";
	type?: string = "";
	etat: number = 0;
	type_id: number = 0;
	media_id?: number;
	media_data?: Media;
	media?: Media[];
	caution: number = 0;
	created_at: string = new Date().toISOString(); // Current date as ISO string
	updated_at: string = new Date().toISOString(); // Current date as ISO string
	place_fr: string = "";
	place_en: string = "";
	taille: number = 0;
	seo_title_fr?: string;
	seo_description_fr?: string;
	seo_title_en?: string;
	seo_description_en?: string;
	is_annonce: boolean = false;
	annonce_prix?: number; // Optional field
	annonce_date?: string; // Optional field (ISO 8601 date string)
	annonce_acquisition_fr?: string;
	annonce_acquisition_en?: string;
	anecdote_fr?: string;
	anecdote_en?: string;
	gamme_id?: number; // Optional field
	gamme?: Gamme;
	grilles?: Grille[];
	is_location: boolean = false;
}
